import SetPagination from "../../pagination/SetPagination";
import ProduccionCardHorizontal from "./ProduccionCardHorizontal";


function ProduccionList({
  produccion,
  get_produccion_list_page,
  count,
  refreshData
}) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">
                Fecha
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Turno
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Soya
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Palma
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Mezcla
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Referencia
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {produccion &&
              produccion.map((produccion, index) => (
                <ProduccionCardHorizontal
                  data={produccion}
                  key={index}
                  index={index}
                  refreshData={refreshData}
                />
              ))} */}

            {produccion && produccion.length > 0 ? (
              produccion.map((produccion, index) => (
                <ProduccionCardHorizontal
                  data={produccion}
                  key={index}
                  index={index}
                  refreshData={refreshData}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_produccion_list_page}
        list={produccion}
        count={count}
        type="medium"
      />
    </div>
  );
}
export default ProduccionList;
