import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img/logo-removebg-preview.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: "Oswald",
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginBottom: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  sectionTitle: {
    fontSize: 18,
    fontFamily: "Oswald",
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 14,
    fontFamily: "Times-Roman",
    marginBottom: 5,
  },
  wrapper: {
    flexDirection: "column",
    border: "1px solid #676767",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  contentKey: {
    fontSize: 14,
    fontFamily: "Oswald",
  },
  contentValue: {
    fontSize: 14,
    fontFamily: "Times-Roman",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 14,
    fontFamily: "Oswald",
  },
  tableCell: {
    margin: 5,
    fontSize: 14,
    fontFamily: "Times-Roman",
  },
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const formatDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  return (
    <>
      {data ? (
        <Document title={`Reporte de fecha ${data.fecha}`}>
          <Page style={styles.body} wrap>
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>{`Reporte de Insumos Recibidos.`}</Text>
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Detalles del Reporte</Text>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Número de Remisión:</Text>
                <Text style={styles.contentValue}>{data.remision}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Fecha:</Text>
                <Text style={styles.contentValue}>{data.fecha}</Text>
              </View>
              <View style={styles.contentRow}>
                {data.planta === "Proveedores" ? (
                  <>
                    <Text style={styles.contentKey}>Proveedor:</Text>
                    <Text style={styles.contentValue}>{data.proveedor}</Text>
                  </>
                ) : (
                  <>
                    <Text style={styles.contentKey}>Planta:</Text>
                    <Text style={styles.contentValue}>{data.planta}</Text>
                  </>
                )}
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Descripción:</Text>
                <Text style={styles.contentValue}>{data.descripcion}</Text>
              </View>
            </View>
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Productos</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Nombre</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Referencia</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Descripción</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Cantidad</Text>
                  </View>
                </View>
                {data.recepcionproducto_set &&
                  data.recepcionproducto_set.map((producto, index) => (
                    <View style={styles.tableRow} key={index}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {producto.producto.nombre}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {producto.producto.referencia}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {producto.producto.descripcion}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {producto.cantidad}
                        </Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        "No se pudieron obtener los datos del cliente"
      )}
    </>
  );
}

export default MyDocument;
