import SetPagination from "../../pagination/SetPagination";
import SalidaInsumosCardHorizontal from "./SalidaInsumosCardHorizontal";

function SalidaInsumosListSearch({
  salidas,
  get_salida_insumos_search_page,
  count,
  search_term,
}) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2"></th>
              <th scope="col" className="p-2">
                Fecha
              </th>
              <th scope="col" className="p-2">
                Remisión
              </th>
              <th scope="col" className="p-2">
                Descricpión
              </th>
              <th scope="col" className="p-2">
                Producto/Cantidad
              </th>
              <th scope="col" className="p-2">
                Planta/Proveedor
              </th>
              <th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {salidas &&
              salidas.map((salida, index) => (
                <SalidaInsumosCardHorizontal
                  data={salida}
                  key={index}
                  index={index}
                />
              ))}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_salida_insumos_search_page}
        list={salidas}
        count={count}
        type={"medium"}
        search_term={search_term}  // Pasar el término de búsqueda a la paginación
      />
    </div>
  );
}
export default SalidaInsumosListSearch;
