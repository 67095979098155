import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {
  IconChevronDown,
  IconHome,
  IconUsers,
  IconBriefcase,
  IconUsersGroup,
  IconCalendar,
  IconEye,
  IconBuildingFactory2
} from '@tabler/icons-react';
import { useSelector } from 'react-redux';

function Sidebar({ selectedItem, handleSidebarClick, darkTheme }) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState({});

  useEffect(() => {
    const pathname = location.pathname;
    const newIsOpen = {};

    navigation.forEach((item) => {
      if (item.children) {
        const hasSelectedChild = item.children.some(
          (child) =>
            child.href === pathname ||
            (child.children && child.children.some((grandchild) => grandchild.href === pathname))
        );
        if (hasSelectedChild) {
          newIsOpen[item.name] = true;
        }

        item.children.forEach((child) => {
          if (child.children) {
            const hasSelectedGrandChild = child.children.some((grandchild) => grandchild.href === pathname);
            if (hasSelectedGrandChild) {
              newIsOpen[child.name] = true;
            }
          }
        });
      }
    });

    setIsOpen(newIsOpen);
  }, [location]);

  const handleDropdownClick = (name) => {
    setIsOpen((prevIsOpen) => ({ ...prevIsOpen, [name]: !prevIsOpen[name] }));
  };

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard/",
      icon: IconHome,
      roles: ["GERENTE_GENERAL", "COORDINADOR_PLANTA_ENVASADO", "COORDINADOR_INVENTARIOS"],
    },
    {
      name: "Planta 01",
      icon: IconBuildingFactory2,
      roles: ["GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"],
      children: [
        { name: "Turneros", href: "/turneros/01", icon: IconCalendar, roles: ["GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
      ],
    },
    {
      name: "Planta 02",
      icon: IconBuildingFactory2,
      roles: ["GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"],
      children: [
        { name: "Turneros", href: "/turneros/02", icon: IconCalendar, roles: ["GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
      ],
    },
    {
      name: "Planta 03",
      icon: IconBuildingFactory2,
      roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"],
      children: [
        {
          name: "Entradas y Salidas",
          icon: IconUsers,
          roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"],
          children: [
            { name: "Entradas", href: "/entradas-p03", icon: IconUsersGroup, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
            {
              name: "Salidas",
              icon: IconBriefcase,
              roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"],
              children: [
                { name: "Salidas", href: "/salidas-p03", icon: IconUsers, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
                { name: "Salida Insumos", href: "/salida-insumos-p03", icon: IconUsers, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
              ],
            },
          ],
        },
        {
          name: "Inventario",
          icon: IconUsers,
          roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"],
          children: [
            { name: "Materiales e Insumos", href: "/inventario-p03", icon: IconUsersGroup, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
            { name: "Saldo Planta", href: "/saldo-planta-p03", icon: IconBriefcase, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL", "COORDINADOR_INVENTARIOS"] },
          ],
        },
        {
          name: "Procesos",
          icon: IconUsersGroup,
          roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL"],
          children: [
            { name: "Referencias", href: "/referencias-p03", icon: IconUsers, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL"] },
            { name: "Producción", href: "/produccion-p03", icon: IconUsers, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL"] },
          ],
        },
        {
          name: "Averias",
          icon: IconUsers,
          roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL"],
          children: [
            { name: "Producción", href: "/averias-produccion-p03", icon: IconUsers, roles: ["COORDINADOR_PLANTA_ENVASADO", "GERENTE_GENERAL"] },
          ],
        },
      ],
    },
    {
      name: "Planta 04",
      icon: IconBuildingFactory2,
      roles: ["GERENTE_GENERAL", "COORDINADOR_INVENTARIOS", "COORDINADOR_PLANTA_SOPLADO"],
      children: [
        { name: "Turneros", href: "/turneros/04", icon: IconCalendar, roles: ["GERENTE_GENERAL", "COORDINADOR_INVENTARIOS", "COORDINADOR_PLANTA_SOPLADO"] },
      ],
    },
    {
      name: "Modificaciones",
      href: "/modificaciones",
      icon: IconEye,
      roles: ["GERENTE_GENERAL", "COORDINADOR_PLANTA_ENVASADO", "COORDINADOR_INVENTARIOS", "COORDINADOR_PLANTA_SOPLADO"],
    },
    {
      name: "Historial de Modificaciones",
      href: "/historial",
      icon: IconEye,
      roles: ["GERENTE_GENERAL"],
    },
  ];

  const renderNavItem = (item, level = 0) => {
    if (!user || !item.roles.includes(user.role)) {
      return null;
    }

    if (item.children) {
      return (
        <li key={item.name} className="mt-0.5 w-full">
          <div
            className={`py-1.5 text-xs ease-nav-brand my-0 mx-1 flex items-center whitespace-nowrap rounded-lg px-4 font-poppins-semibold transition-colors cursor-pointer ${
              selectedItem === item.name
                ? "font-poppins-semibold text-white"
                : darkTheme
                ? "text-gray-300"
                : "text-gray-700"
            }`}
            onClick={() => handleDropdownClick(item.name)}
          >
            <div
              className={`mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-1.5 ${
                selectedItem === item.name
                  ? "bg-gradient-to-tl from-[#E62514] to-[#AC1A17]"
                  : darkTheme
                  ? "bg-gray-700"
                  : "bg-gray-200"
              } transition-colors duration-200`}
            >
              <item.icon
                className={`text-${
                  selectedItem === item.name ? "white" : darkTheme ? "white" : "black"
                }`}
              />
            </div>
            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
              {item.name}
            </span>
            <IconChevronDown
              className={`h-5 w-5 text-gray-600 transition-transform ${
                isOpen[item.name] ? "rotate-180 transform" : ""
              }`}
            />
          </div>
          {isOpen[item.name] && (
            <ul className="ml-4 mt-0 flex flex-col gap-2">
              {item.children.map((child) => renderNavItem(child, level + 1))}
            </ul>
          )}
        </li>
      );
    } else {
      return (
        <li key={item.name} className="w-full">
          <NavLink
            to={item.href}
            className={`py-1.5 text-xs ease-nav-brand my-0 mx-1 flex items-center whitespace-nowrap rounded-lg px-4 font-poppins-semibold transition-colors ${
              selectedItem === item.name
                ? "bg-gradient-to-tl from-[#E62514] to-[#AC1A17] font-poppins-semibold text-white"
                : darkTheme
                ? "text-gray-300"
                : "text-gray-700"
            }`}
            onClick={() => handleSidebarClick(item.name)}
          >
            <div
              className={`mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-1.5 ${
                selectedItem === item.name
                  ? "bg-gradient-to-tl from-[#E62514] to-[#AC1A17]"
                  : darkTheme
                  ? "bg-[#E62514]"
                  : "bg-gray-200"
              } transition-colors duration-200`}
            >
              <item.icon
                className={`text-${
                  selectedItem === item.name ? "white" : darkTheme ? "white" : "black"
                }`}
              />
            </div>
            <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
              {item.name}
            </span>
          </NavLink>
        </li>
      );
    }
  };

  return (
    <ul className="flex flex-col pl-0 mb-0">
      {navigation.map((item) => renderNavItem(item))}
    </ul>
  );
}

export default Sidebar;
