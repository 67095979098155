import SetPagination from "../../pagination/SetPagination";
import AveriasProduccionP03Horizontal from "./AveriasProduccionP03CardHorizontal";

function AveriasProduccionP03List({
  averias_producciones,
  count,
  get_averias_produccion_list,
  get_averias_produccion_list_page,
}) {

  // const produccionesConAverias = averias_producciones.filter((produccion) => produccion.averias.length > 0);
  return (
    <div className="overflow-hidden bg-white dark:bg-gray-800">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Turno</th>
              <th scope="col" className="p-2 dark:text-white">Averias</th>
              <th scope="col" className="p-2 dark:text-white">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {/* {produccionesConAverias.map((data, idx) => (
              <AveriasProduccionP03Horizontal
                data={data}
                key={idx}
                index={idx}
              />
            ))} */}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_averias_produccion_list_page}
        list={averias_producciones}
        count={count}
        type={"large"}
      />
    </div>
  );
}

export default AveriasProduccionP03List;
