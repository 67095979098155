import {
GET_HISTORIAL_LIST_SUCCESS,
GET_HISTORIAL_LIST_FAIL,
GET_HISTORIAL_SUCCESS,
GET_HISTORIAL_FAIL

} from "./types"
import axios from "axios";

export const get_historial_list =  (planta = null) =>
async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}/historial/`;
  let params = new URLSearchParams();
  if (planta !== null && planta !== "") {
    params.append("planta", planta);
  }

  url += `?${params.toString()}`;

  try {
    const res = await axios.get(url, config);

    dispatch({
      type: GET_HISTORIAL_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_HISTORIAL_LIST_FAIL,
    });
  }
};

export const get_historial_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/historial/?p=${p}`,
      config
    );

    dispatch({
      type: GET_HISTORIAL_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_HISTORIAL_LIST_FAIL,
    });
  }

}

export const get_historial = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/historial/${id}`,
      config
    );

    dispatch({
      type: GET_HISTORIAL_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_HISTORIAL_FAIL,
    });
  }

}