import SetPagination from "../../pagination/SetPagination";
import InventarioCardHorizontal from "./InventarioCardHorizontal";
function InventarioListSearch({
  inventario,
  get_inventario_search_page,
  count,
  search_term
}) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2"></th>
              <th scope="col" className="p-2">
                Material o Insumo
              </th>
              <th scope="col" className="p-2">
                Entradas
              </th>
              <th scope="col" className="p-2">
                Salidas
              </th>
              <th scope="col" className="p-2">
                Inventario
              </th>
              <th scope="col" className="p-2">
                Costo Unitario
              </th>
              <th scope="col" className="p-2">
                Costo Total
              </th>
              <th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>

            {inventario && inventario.length > 0 ? (
              inventario.map((inventario, index) => (
                <InventarioCardHorizontal
                  data={inventario}
                  key={index}
                  index={index}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
     <SetPagination
        list_page={get_inventario_search_page}
        list={inventario}
        count={count}
        type={"small"}
        search_term={search_term}  // Pasar el término de búsqueda a la paginación
      />

    </div>
  );
}
export default InventarioListSearch;
