import { useParams } from "react-router-dom";
import { get_inventario } from "../../../redux/actions/P03/p03";
import { PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { useEffect } from "react";
import MyDocument from "../../../components/pdf/P03/inventario/ReportMaterialesInsumos";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";

function ReporteMaterialesInsumos({ get_inventario, inventario }) {
  const params = useParams();
  const id = params.uid;

  useEffect(() => {
    get_inventario(id);
  }, [get_inventario, id]);

  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Inventario de materiales e insumos Planta 03.
            </h3>
          </div>
        </div>
        <div className="mt-10 container mx-auto max-w-5xl">
          {inventario ? (
            <div className="">
              <PDFViewer className="w-full h-[75vh] md:h-[85vh]">
                <MyDocument data={inventario} />
              </PDFViewer>
            </div>
          ) : (
            <div className="flex items-center justify-center h-96">
              <div className="w-10 h-10 border-t-2 border-b-2 border-[#0096C4] rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  inventario: state.inventario.inventario,
});

export default connect(mapStateToProps, { get_inventario })(ReporteMaterialesInsumos);
