import SetPagination from "../../pagination/SetPagination";
import SalidasCardHorizontal from "./SalidasCardHorizontal";

function SalidasListSearch({ salida,search_salida_page,
  count,
  search_term }) {
  return (
    <div className="overflow-hidden bg-white">
    <div className="relative overflow-x-auto">
      <table className="table text-gray-500">
        <thead className="text-xs text-gray-700 uppercase">
          <tr>
            <th scope="col" className="p-2"></th>
            <th scope="col" className="p-2">
              Fecha
            </th>
            <th scope="col" className="p-2">
              Remision
            </th>
            <th scope="col" className="p-2">
              Cliente
            </th>
            <th scope="col" className="p-2">
              Conductor/Placa
            </th>
            <th scope="col" className="p-2">
              Referencias
            </th>
            <th scope="col" className="p-2">
              Lote/Salida
            </th>
            <th scope="col" className="p-2">
              Observaciones
            </th>
            <th scope="col" className="p-2">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {salida &&
            salida.map((salida, index) => (
              <SalidasCardHorizontal
                data={salida}
                key={index}
                index={index}
              />
            ))}
        </tbody>
      </table>
    </div>

      <SetPagination
        list_page={search_salida_page}
        list={salida}
        count={count}
        type={"medium"}
        search_term={search_term}  // Pasar el término de búsqueda a la paginación
      />
  </div>
  );
}

export default SalidasListSearch;