import React from "react";
import SetPagination from "../../pagination/SetPagination";
import SaldoPlantaCardHorizontal from "./SaldoPlantaCardHorizontal";

function SaldoPlantaList({ saldos, get_saldo_planta_list_page, count }) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white">#</th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Código</th>
              <th scope="col" className="p-2 dark:text-white">Etiqueta</th>
              <th scope="col" className="p-2 dark:text-white">Saldo</th>
              <th scope="col" className="p-2 dark:text-white">Detalles</th>
            </tr>
          </thead>
          <tbody>
            {/* {saldos &&
              saldos.map((saldo, index) => (
                <SaldoPlantaCardHorizontal
                  data={saldo}
                  key={index}
                  index={index}
                />
              ))} */}

            {saldos && saldos.length > 0 ? (
              saldos.map((saldo, index) => (
                <SaldoPlantaCardHorizontal
                  data={saldo}
                  key={index}
                  index={index}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_saldo_planta_list_page}
        list={saldos}
        count={count}
        type={"medium"}
      />
    </div>
  );
}

export default SaldoPlantaList;
