import { useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { get_inventario } from "../../../redux/actions/P03/p03";
import axios from "axios";
import Toast from "../../../components/Alert";
import { CircleLoader } from "react-spinners";

function InventarioP03Edit({ get_inventario, inventario }) {
  const user = useSelector((state) => state.auth.user);
  const params = useParams();
  const id = params.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Agregar campo de justificación al formData
  const [formData, setFormData] = useState({
    inventario: "", 
    costos_unitario: "",
    justificacion: ""  // Nuevo campo para la justificación
  });

  useEffect(() => {
    get_inventario(id);
  }, [get_inventario, id]);

  useEffect(() => {
    if (inventario) {
      setFormData({
        inventario: inventario.inventario || 0, 
        costos_unitario: inventario.costos_unitario || "",
        justificacion: ""  // Asegúrate de que la justificación esté vacía al inicio
      });
    }
  }, [inventario]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validar que la justificación sea requerida si el usuario es COORDINADOR_INVENTARIOS
    if (user.role === "COORDINADOR_INVENTARIOS" && !formData.justificacion) {
      Toast({
        tipo: "error",
        mensaje: "Debes proporcionar una justificación para modificar el inventario.",
      });
      return;
    }

    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    // Crear dinámicamente los datos a enviar en función del rol del usuario
    let dataToSend = {};

    if (user.role === "COORDINADOR_PLANTA_ENVASADO") {
      // Solo enviar costos_unitario para COORDINADOR_PLANTA_ENVASADO
      dataToSend = {
        costos_unitario: formData.costos_unitario,
      };
    } else if (user.role === "COORDINADOR_INVENTARIOS") {
      // Enviar inventario y justificación para COORDINADOR_INVENTARIOS
      dataToSend = {
        inventario: formData.inventario,
        justificacion: formData.justificacion,
      };
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/inventario/update/${id}/`;

      const res = await axios.put(url, dataToSend, config);
      if (res.status === 200) {
        setLoading(false);
        Toast({
          tipo: "success",
          mensaje: "Inventario actualizado correctamente!",
        });
        navigate("/inventario-p03");
      }
    } catch (error) {
      setLoading(false);
      Toast({ tipo: "error", mensaje: "Error al actualizar el inventario" });
    }
  };

  return (
    <DashboardLayout>
      <div className="w-full h-100 shadow-card rounded-lg overscroll-y-auto overflow-y-auto dark:text-white">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Inventario
        </h1>
        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Información del inventario a editar.
          </div>
          <div className="border border-gray-300 dark:border-gray-700 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="flex flex-col">
              <label htmlFor="nombre" className="label-text dark:text-gray-300">
                Nombre del Producto
              </label>
              <input
                type="text"
                name="nombre"
                id="nombre"
                value={inventario?.producto?.nombre || ""}
                disabled
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="referencia" className="label-text dark:text-gray-300">
                Referencia
              </label>
              <input
                type="text"
                name="referencia"
                id="referencia"
                value={inventario?.producto?.referencia || ""}
                disabled
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="descripcion" className="label-text dark:text-gray-300">
                Descripción
              </label>
              <input
                type="text"
                name="descripcion"
                id="descripcion"
                value={inventario?.producto?.descripcion || ""}
                disabled
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="entradas" className="label-text dark:text-gray-300">
                Entradas
              </label>
              <input
                type="number"
                name="entradas"
                id="entradas"
                value={inventario?.entradas || 0}
                disabled
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="salidas" className="label-text dark:text-gray-300">
                Salidas
              </label>
              <input
                type="number"
                name="salidas"
                id="salidas"
                value={inventario?.salidas || 0}
                disabled
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="inventario" className="label-text dark:text-gray-300">
                Inventario
              </label>
              <input
                type="number"
                name="inventario"
                id="inventario"
                onChange={(e) => onChange(e)}
                value={formData.inventario}
                disabled={user && user.role !== "COORDINADOR_INVENTARIOS"}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            
            <div className="flex flex-col">
              <label htmlFor="costos_unitario" className="label-text dark:text-gray-300">
                Costos Unitario
              </label>
              <input
                type="number"
                name="costos_unitario"
                id="costos_unitario"
                value={formData.costos_unitario}
                onChange={(e) => onChange(e)}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                disabled={user && user.role !== "COORDINADOR_PLANTA_ENVASADO"} 
              />
            </div>

            {/* Campo de justificación solo aparece si el usuario es COORDINADOR_INVENTARIOS */}
            {user && user.role === "COORDINADOR_INVENTARIOS" && (
              <div className="flex flex-col">
                <label htmlFor="justificacion" className="label-text dark:text-gray-300">
                  Justificación de la Modificación
                </label>
                <textarea
                  name="justificacion"
                  id="justificacion"
                  value={formData.justificacion}
                  onChange={(e) => onChange(e)}
                  required={user.role === "COORDINADOR_INVENTARIOS"} // Requerido solo para COORDINADOR_INVENTARIOS
                  className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            )}

            <div className="flex flex-col">
              <label htmlFor="costos_total" className="label-text dark:text-gray-300">
                Costos Total
              </label>
              <input
                type="number"
                name="costos_total"
                id="costos_total"
                value={inventario?.costos_total || 0}
                disabled
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>
  
          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button type="submit" className="btn border bg-[#DC2917] text-white">
                Actualizar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  inventario: state.inventario.inventario,
});

export default connect(mapStateToProps, { get_inventario })(InventarioP03Edit);
