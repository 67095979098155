import SetPagination from "../pagination/SetPagination";
import ModificacionesCardHorizontal from "./ModificacionesCardHorizontal";

function ModificacionesListSearch({
  modificaciones,
  get_modicaciones_list,
  get_averias_produccion_list_page,
  count,
}) {
  
  return (
    <div className="overflow-hidden bg-white dark:bg-gray-800">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Tipo</th>
              <th scope="col" className="p-2 dark:text-white">Producto</th>
              <th scope="col" className="p-2 dark:text-white">Cantidad</th>
              <th scope="col" className="p-2 dark:text-white">Descripcion</th>
              <th scope="col" className="p-2 dark:text-white">Encargado</th>
              <th scope="col" className="p-2 dark:text-white">Planta</th>
              <th scope="col" className="p-2 dark:text-white">Estado</th>
              <th scope="col" className="p-2 dark:text-white">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {modificaciones&&modificaciones.map((data, idx) => (
              <ModificacionesCardHorizontal
                data={data}
                key={idx}
                index={idx}
              />
            ))}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_averias_produccion_list_page}
        list={get_modicaciones_list}
        count={count}
        type={"medium"}
      />
    </div>
  );
}

export default ModificacionesListSearch;
