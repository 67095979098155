import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ModificacionesCardHorizontal({ data, index }) {
  const user = useSelector((state) => state.auth.user);
  return (
    <tr className="bg-white border-b dark:bg-gray-800">
      <td className="p-2 dark:text-white"></td>
      <td className="p-2 dark:text-white">{data.fecha}</td>
      <td className="p-2 dark:text-white">{data.tipo}</td>
      <td className="p-2 dark:text-white">{data.producto}</td>
      <td className="p-2 dark:text-white">{data.cantidad}</td>
      <td className="p-2 dark:text-white">{data.descripcion}</td>
      <td className="p-2 dark:text-white">{data.nombre}</td>
      <td className="p-2 dark:text-white">{data.user.plant}</td>
      <td className="p-2 dark:text-white">
        {data.status === false ? "Pendiente" : "Realizado"}
      </td>
      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">
          {
            data.status === false && (user && user.role === "COORDINADOR_PLANTA_ENVASADO") ||
            (user && user.role === "COORDINADOR_PLANTA_SOPLADO")? (
              <div className="flex tooltip" data-tip="Editar">
            <Link
              to={`/modificacion/editar/${data.id}`}
              className="p-1 text-white bg-yellow-500 rounded-full"
            >
              <IconPencil />
            </Link>
          </div> ) : null
          }
          <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/modificaciones/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default ModificacionesCardHorizontal;
