import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { get_productos_list } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import { useNavigate } from "react-router-dom";
import Toast from "../../../components/Alert";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import ProductoModal from "../../../components/P03/entradas/ProductoModal";

function EntradasP03Form({ get_productos_list, productos }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [formData, setFormData] = useState({
    fecha: "",
    remision: "",
    descripcion: "",
    recepcionproducto_set: [],
    planta: "",
    proveedor: "",
  });

  const dropdownRef = useRef(null);

  useEffect(() => {
    get_productos_list();
  }, [get_productos_list]);

  const filteredProductos = productos
    ? query === ""
      ? productos
      : productos.filter((producto) =>
          producto.nombre
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const selectedProductIds = formData.recepcionproducto_set.map(
    (p) => p.producto
  );
  const availableProductos = filteredProductos.filter(
    (producto) => !selectedProductIds.includes(producto.id)
  );
  const filteredProductosDropdown = availableProductos.slice(0, 10);

  const { fecha, remision, descripcion, planta, proveedor } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (formData.recepcionproducto_set.length === 0) {
      Toast({
        tipo: "error",
        mensaje: "Debe agregar al menos un producto con su cantidad.",
      });
      return false;
    }
    for (let i = 0; i < formData.recepcionproducto_set.length; i++) {
      const prod = formData.recepcionproducto_set[i];
      if (!prod.producto || !prod.cantidad) {
        Toast({
          tipo: "error",
          mensaje: "Cada producto debe tener una cantidad.",
        });
        return false;
      }
    }

    if (
      !formData.fecha ||
      !formData.remision ||
      !formData.descripcion ||
      !formData.planta
    ) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, complete todos los campos obligatorios.",
      });
      return false;
    }

    if (formData.planta === "Proveedores" && !formData.proveedor) {
      Toast({
        tipo: "error",
        mensaje: "Debe proporcionar el nombre del proveedor.",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/entradas/create/`,
        formData,
        config
      );

      if (res.status === 201) {
        Toast({
          tipo: "success",
          mensaje: "¡Entrada registrada correctamente!",
        });
        navigate("/entradas-p03");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.remision.includes(
          "Ya existe Recepcion de Materiales con este remision."
        )
      ) {
        Toast({
          tipo: "error",
          mensaje: "Ya existe una entrada con este número de remisión.",
        });
      } else {
        Toast({
          tipo: "error",
          mensaje: "Ocurrió un error al registrar la entrada.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const openAddModal = () => {
    setIsEditMode(false);
    setEditData(null);
    setIsModalOpen(true);
  };

  const openEditModal = (producto) => {
    setIsEditMode(true);
    setEditData(producto);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const refreshProductos = () => {
    get_productos_list();
  };

  const addProducto = () => {
    setFormData({
      ...formData,
      recepcionproducto_set: [
        ...formData.recepcionproducto_set,
        { producto: "", cantidad: "" },
      ],
    });
    setQuery("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Registro de Entradas.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la entrada.
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-white">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={fecha}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                max={new Date().toISOString().split("T")[0]}
              />
            </div>

            <div className="form-control">
              <label htmlFor="remision" className="label">
                <span className="label-text dark:text-white">Remision</span>
              </label>
              <input
                type="text"
                name="remision"
                value={remision}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="descripcion" className="label">
                <span className="label-text dark:text-white">Descripcion</span>
              </label>
              <input
                type="text"
                name="descripcion"
                value={descripcion}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="planta" className="label">
                <span className="label-text dark:text-white">
                  Planta/Proveedor
                </span>
              </label>
              <select
                name="planta"
                value={planta}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Selecciona una planta o proveedor</option>
                <option value="01">Planta Principal</option>
                <option value="02">Planta Patios</option>
                <option value="04">Planta Soplado</option>
                <option value="05">Bodega</option>
                <option value="Proveedores">Proveedor</option>
              </select>
            </div>

            {planta === "Proveedores" && (
              <div className="form-control">
                <label htmlFor="proveedor" className="label">
                  <span className="label-text dark:text-white">
                    Nombre del Proveedor
                  </span>
                </label>
                <input
                  type="text"
                  name="proveedor"
                  value={proveedor}
                  onChange={(e) => onChange(e)}
                  required
                  className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            )}
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Productos
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.recepcionproducto_set.map((prod, index) => (
              <div key={index} className="mb-3">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                  <div className="form-control">
                    <label htmlFor="producto" className="label">
                      <span className="label-text dark:text-white">
                        Producto
                      </span>
                    </label>
                    <div className="relative" ref={dropdownRef}>
                      <div className="inline-flex items-center justify-between w-full">
                        <button
                          type="button"
                          onClick={() => setDropdownOpen(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {prod.producto
                              ? `${
                                  productos.find((p) => p.id === prod.producto)
                                    ?.nombre
                                } - ${
                                  productos.find((p) => p.id === prod.producto)
                                    ?.referencia
                                }`
                              : "Seleccionar Producto"}
                          </span>
                          <IconEye className="ml-2" size={20} />
                        </button>
                      </div>
                      {dropdownOpen === index && (
                        <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                          <input
                            id="search-input"
                            className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
                            type="text"
                            placeholder="Buscar productos"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="max-h-60 overflow-y-auto">
                            {filteredProductosDropdown.length === 0 ? (
                              <div className="px-4 py-2 text-gray-700 dark:text-white">
                                No se encontraron productos
                              </div>
                            ) : (
                              filteredProductosDropdown.map((prodItem) => (
                                <div
                                  key={prodItem.id}
                                  onClick={() => {
                                    const newProds = [
                                      ...formData.recepcionproducto_set,
                                    ];
                                    newProds[index].producto = prodItem.id;
                                    setFormData({
                                      ...formData,
                                      recepcionproducto_set: newProds,
                                    });
                                    setDropdownOpen(null);
                                    setQuery("");
                                  }}
                                  className="cursor-pointer px-4 py-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                                >
                                  {prodItem.nombre} - {prodItem.referencia}
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-control">
                    <label htmlFor="cantidad" className="label">
                      <span className="label-text dark:text-white">
                        Cantidad
                      </span>
                    </label>
                    <input
                      type="number"
                      name="cantidad"
                      value={prod.cantidad}
                      onChange={(e) => {
                        const newProds = [...formData.recepcionproducto_set];
                        newProds[index].cantidad = e.target.value;
                        setFormData({
                          ...formData,
                          recepcionproducto_set: newProds,
                        });
                      }}
                      required
                      className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  <div className="flex items-center justify-start space-x-2">
                    {prod.producto ? (
                      <div className="flex flex-col items-center">
                        <label htmlFor="eliminar" className="label">
                          <span className="label-text dark:text-white">
                            Eliminar
                          </span>
                        </label>
                        <button
                          type="button"
                          onClick={() => {
                            const newProds = [
                              ...formData.recepcionproducto_set,
                            ];
                            newProds.splice(index, 1);
                            setFormData({
                              ...formData,
                              recepcionproducto_set: newProds,
                            });
                          }}
                          className="p-2 bg-red-500 text-white rounded-md"
                        >
                          <IconTrash size={20} />
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col items-center">
                          <label htmlFor="crear" className="label">
                            <span className="label-text dark:text-white">
                              Crear
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={openAddModal}
                            className="p-2 bg-blue-500 text-white rounded-md"
                          >
                            <IconPencil size={20} />
                          </button>
                        </div>
                        <div className="flex flex-col items-center">
                          <label htmlFor="eliminar" className="label">
                            <span className="label-text dark:text-white">
                              Eliminar
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              const newProds = [
                                ...formData.recepcionproducto_set,
                              ];
                              newProds.splice(index, 1);
                              setFormData({
                                ...formData,
                                recepcionproducto_set: newProds,
                              });
                            }}
                            className="p-2 bg-red-500 text-white rounded-md"
                          >
                            <IconTrash size={20} />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={addProducto}
              className="btn border bg-blue-500 text-white dark:bg-blue-700"
            >
              Añadir Producto
            </button>
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>

      <ProductoModal
        show={isModalOpen}
        handleClose={closeModal}
        isEditMode={isEditMode}
        editData={editData}
        refreshProductos={refreshProductos}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  productos: state.productos.productos || [],
});

export default connect(mapStateToProps, { get_productos_list })(
  EntradasP03Form
);
