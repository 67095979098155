import { useState } from "react";
import { IconEye, IconPencil, IconTrash, IconEdit, IconBan, IconSend } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import Toast from "../../Alert";
import ReferenciaModal from "./ReferenciaModal";
import AveriaModal from "../averias/AveriaModal";
import ProduccionModal from "./ProduccionModal"; // Importa el nuevo modal

function ProduccionCardHorizontal({ data, index, refreshData }) {
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showAveriaModal, setShowAveriaModal] = useState(false);
  const [showProduccionModal, setShowProduccionModal] = useState(false);

  const handleOpenModal = (referencia) => {
    setIsEditMode(true);
    setEditData(referencia);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditMode(false);
    setEditData(null);
  };

  const handleSaveReferencia = async (updatedReferencia) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/produccion/modificar/referencia/`, {
        ...updatedReferencia,
        produccion_referencia: data.id
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: 'application/json'
        }
      });
      if (response.status === 201) {
        Toast({
          tipo: "success",
          mensaje: "¡Referencia modificada correctamente!",
        });
      }
    } catch (error) {
      if (error.response && 
        error.response.status === 400 && 
        error.response.data.detail.includes(
        "Esta referencia ya ha sido modificada.")){
        Toast({
          tipo: "error",
          mensaje: "Esta referencia ya ha sido modificada.",
        });
        } 
      else{
        Toast({
          tipo: "error",
          mensaje: "Ocurrió un error al modificar la referencia.",
        });
      }
    } finally {
      handleCloseModal();
    }
  };

  const handleOpenAveriaModal = () => {
    setShowAveriaModal(true);
  };

  const handleCloseAveriaModal = () => {
    setShowAveriaModal(false);
  };

  const handleOpenProduccionModal = () => {
    setShowProduccionModal(true);
  };

  const handleCloseProduccionModal = () => {
    setShowProduccionModal(false);
  };

  return (
    <>
      <tr className="border-b">
        <td className="p-2 dark:text-white">{index + 1}</td>
        <td className="p-2 dark:text-white">{data.fecha}</td>
        <td className="p-2 dark:text-white">{data.turno === "A" ? "Diurno" : "Nocturno"}</td>
        <td className="p-2 dark:text-white">
          {data.lote_soya && (
            <div className="flex text-xs">
              <span className="font-bold">Lote: </span>
              <span className="flex ml-1">{data.lote_soya}</span>
            </div>
          )}
          {data.fv_soya && (
            <div className="flex text-xs">
              <span className="font-bold">Fecha: </span>
              <span className="flex ml-1">{data.fv_soya}</span>
            </div>
          )}
        </td>
        <td className="p-2 dark:text-white">
          {data.lote_palma && (
            <div className="flex text-xs">
              <span className="font-bold">Lote: </span>
              <span className="flex ml-1">{data.lote_palma}</span>
            </div>
          )}
          {data.fv_palma && (
            <div className="flex text-xs">
              <span className="font-bold">Fecha: </span>
              <span className="flex ml-1">{data.fv_palma}</span>
            </div>
          )}
        </td>
        <td className="p-2 dark:text-white">
          {data.lote_mezcla && (
            <div className="flex text-xs">
              <span className="font-bold">Lote: </span>
              <span className="flex ml-1">{data.lote_mezcla}</span>
            </div>
          )}
          {data.fv_mezcla && (
            <div className="flex text-xs">
              <span className="font-bold">Fecha: </span>
              <span className="flex ml-1">{data.fv_mezcla}</span>
            </div>
          )}
        </td>
        <td className="p-2 dark:text-white">
          {data.produccionreferencia_set &&
            data.produccionreferencia_set.map((referencia, idx) => (
              <div key={idx} className="mb-2 flex items-center justify-between">
                <div>
                  <div className="flex text-xs">
                    <span className="font-bold">Referencia: </span>
                    <span className="flex ml-1">
                      {referencia.referencia.codigo}
                    </span>
                  </div>
                  <div className="flex text-xs">
                    <span className="font-bold">Cantidad: </span>
                    <span className="flex ml-1">{referencia.cantidad}</span>
                  </div>
                </div>
                {!data.terminada && (
                  <div className="flex tooltip" data-tip="Modificar Referencia">
                    <button
                      className="p-1 text-white bg-red-500 rounded-full ml-2"
                      title="Modificar Referencia"
                      onClick={() => handleOpenModal(referencia)}
                    >
                      <IconEdit />
                    </button>
                  </div>
                )}
              </div>
            ))}
        </td>
        <td className="p-2 dark:text-white">
          <div className="flex items-center gap-2">
            {!data.terminada && (
              <>
                <div className="flex tooltip" data-tip="Editar">
                  <Link
                    to={`/produccion-p03/editar/${data.id}`}
                    className="p-1 text-white bg-yellow-500 rounded-full"
                    title="Editar"
                  >
                    <IconPencil />
                  </Link>
                </div>
                <div className="flex tooltip" data-tip="Eliminar">
                  <button
                    className="p-1 text-white bg-red-500 rounded-full"
                    title="Eliminar"
                  >
                    <IconTrash />
                  </button>
                </div>
                <div className="flex tooltip" data-tip="Averias">
                  <button
                    className="p-1 text-white bg-red-500 rounded-full"
                    title="Averias"
                    onClick={handleOpenAveriaModal}
                  >
                    <IconBan />
                  </button>
                </div>
                <div className="flex tooltip" data-tip="Marcar como Terminada">
                  <button
                    className="p-1 text-white bg-green-500 rounded-full"
                    title="Marcar como Terminada"
                    onClick={handleOpenProduccionModal}
                  >
                    <IconSend />
                  </button>
                </div>
              </>
            )}
            <div className="flex tooltip" data-tip="Ver Pdf">
              <Link
                to={`/produccion-p03/ver/${data.id}`}
                className="p-1 text-white bg-blue-500 rounded-full"
                title="Ver Pdf"
              >
                <IconEye />
              </Link>
            </div>
          </div>
        </td>
      </tr>
      <ReferenciaModal
        show={showModal}
        handleClose={handleCloseModal}
        isEditMode={isEditMode}
        editData={editData}
        onSave={handleSaveReferencia}
      />
      <AveriaModal
        show={showAveriaModal}
        handleClose={handleCloseAveriaModal}
        produccionId={data.id}
      />
      <ProduccionModal
        show={showProduccionModal}
        handleClose={handleCloseProduccionModal}
        produccionId={data.id}
        refreshData={refreshData} // Pasa la función de refresco de datos
      />
    </>
  );
}

export default ProduccionCardHorizontal;
