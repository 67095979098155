import { useState } from "react";
import { IconEye, IconPencil, IconTrash, IconSend } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import SalidaMarcadaModal from "./SalidaMarcadaModal"; // Importa el nuevo modal

function SalidasCardHorizontal({ data, index, refreshData }) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <tr className="bg-white border-b dark:bg-gray-900">
        <td className="p-2"></td>
        <td className="p-2">{data.fecha}</td>
        <td className="p-2">{data.remision}</td>
        <td className="p-2">{data.cliente}</td>
        <td className="p-2">
          <div className="flex text-xs">
            <span className="font-bold">Conductor: </span>
            <span className="flex ml-1">{data.conductor}</span>
          </div>
          <div className="flex text-xs">
            <span className="font-bold">Placa: </span>
            <span className="flex ml-1">{data.placa}</span>
          </div>
        </td>
        <td className="p-2">
          {data.salidareferencia_set && data.salidareferencia_set.map((salidaReferencia, idx) => (
            <div key={idx} className="mb-2">
              <div className="flex text-xs">
                <span className="font-bold">Codigo: </span>
                <span className="flex ml-1">{salidaReferencia.referencia.codigo}</span>
              </div>
              <div className="flex text-xs">
                <span className="font-bold">Etiqueta: </span>
                <span className="flex ml-1">{salidaReferencia.referencia.etiqueta}</span>
              </div>
              <div className="flex text-xs">
                <span className="font-bold">Cantidad: </span>
                <span className="flex ml-1">{salidaReferencia.cantidad}</span>
              </div>
            </div>
          ))}
        </td>
        <td className="p-2">
          <div className="flex text-xs">
            <span className="font-bold">Lote: </span>
            <span className="flex ml-1">{data.lote}</span>
          </div>
          <div className="flex text-xs">
            <span className="font-bold">Salida: </span>
            <span className="flex ml-1">{data.tipo_salida}</span>
          </div>
        </td>
        <td className="p-2">{data.observaciones}</td>

        <td className="p-2">
          <div className="flex items-center gap-2">
            <div className="flex tooltip" data-tip="Ver Pdf">
              <Link
                to={`/salidas-p03/ver/${data.id}`}
                className="p-1 text-white bg-blue-500 rounded-full"
              >
                <IconEye />
              </Link>
            </div>
            {data.marcada === false && (
              <>
                <div className="flex tooltip" data-tip="Editar">
                  <Link
                    to={`/salidas-p03/editar/${data.id}`}
                    className="p-1 text-white bg-yellow-500 rounded-full"
                  >
                    <IconPencil />
                  </Link>
                </div>
                <div className="flex tooltip" data-tip="Eliminar">
                  <button className="p-1 text-white bg-red-500 rounded-full">
                    <IconTrash />
                  </button>
                </div>
                <div className="flex tooltip" data-tip="Marcar como Marcada">
                  <button
                    className="p-1 text-white bg-green-500 rounded-full"
                    title="Marcar como Marcada"
                    onClick={handleOpenModal}
                  >
                    <IconSend />
                  </button>
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
      <SalidaMarcadaModal
        show={showModal}
        handleClose={handleCloseModal}
        salidaId={data.id}
        refreshData={refreshData} // Pasa la función de refresco de datos
      />
    </>
  );
}

export default SalidasCardHorizontal;
