import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logo-removebg-preview.png";

// Registrar la fuente
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Estilos para el PDF
const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 20,
  },
  text: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 18,
    fontFamily: "Oswald",
    marginBottom: 10,
    color: "#333333",
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  contentKey: {
    fontSize: 14,
    fontFamily: "Oswald",
    color: "#333333",
  },
  contentValue: {
    fontSize: 14,
    fontFamily: "Times-Roman",
    color: "#555555",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  wrapper: {
    border: "1px solid #dddddd",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 100,
  },
  signatureImage: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 150,
    height: 50,
  },
});

// Función para formatear fechas
const formatDateTime = (date) => {
  if (!date) return "Fecha no disponible";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(new Date(date));
};

// Componente principal para generar el documento PDF
function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  // Construir la URL completa de la firma
  const firmaUrl = `${process.env.REACT_APP_API_URL}${data.firma}`;

  // Verificar el estado y asignar el texto correcto
  const statusText = data.status ? "Completado" : "Pendiente";

  return (
    <>
      {data ? (
        <Document title={`Reporte de Cambio - ${data.producto}`}>
          <Page style={styles.body}>
            {/* Título principal */}
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>Reporte de Cambio</Text>

            {/* Sección de detalles del historial */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Detalles del Cambio</Text>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Producto:</Text>
                <Text style={styles.contentValue}>{data.producto}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Tipo de Cambio:</Text>
                <Text style={styles.contentValue}>{data.tipo}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Cantidad:</Text>
                <Text style={styles.contentValue}>{data.cantidad}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Planta:</Text>
                <Text style={styles.contentValue}>{data.user.plant}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Usuario:</Text>
                <Text style={styles.contentValue}>
                  {data.user.first_name} {data.user.last_name}
                </Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Fecha de Modificación:</Text>
                <Text style={styles.contentValue}>
                  {formatDateTime(data.fecha)}
                </Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Estado:</Text>
                <Text style={styles.contentValue}>{statusText}</Text>
              </View>
            </View>

            {/* Sección de descripción */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Descripción</Text>
              <Text style={styles.text}>{data.descripcion}</Text>
            </View>

            {/* Sección para la firma */}
            {data.firma && (
              <View style={styles.wrapper}>
                <Text style={styles.sectionTitle}>Firma</Text>
                <Image style={styles.signatureImage} src={firmaUrl} />
              </View>
            )}

            {/* Pie de página */}
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        <Text>No se pudieron obtener los datos</Text>
      )}
    </>
  );
}

export default MyDocument;
