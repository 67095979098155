import SetPagination from "../../pagination/SetPagination";
import SalidaInsumosCardHorizontal from "./SalidaInsumosCardHorizontal";


function SalidaInsumosList({
  salidas,
  get_salida_insumos_list,
  get_salida_insumos_list_page,
  count,
  refreshData
}) {

  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Remisión</th>
              <th scope="col" className="p-2 dark:text-white">Descripción</th>
              <th scope="col" className="p-2 dark:text-white">Producto/Cantidad</th>
              <th scope="col" className="p-2 dark:text-white">Planta/Proveedor</th>
              <th scope="col" className="p-2 dark:text-white">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {salidas && salidas.length > 0 ? (
              salidas.map((salida, index) => (
                <SalidaInsumosCardHorizontal
                  data={salida}
                  key={index}
                  index={index}
                  refreshData={refreshData}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_salida_insumos_list_page}
        list={salidas}
        count={count}
        type={"medium"}
      />
    </div>
  );
}
export default SalidaInsumosList;
