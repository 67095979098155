import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { IconFilter } from "@tabler/icons-react";
import { get_inventario_search, get_inventario_search_page } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import InventarioListSearch from "../../../components/P03/inventario/InventarioListSearch";

function SearchInventario({ get_inventario_search, get_inventario_search_page,inventario,count }) {
  const [planta, setPlanta] = useState("");
  const params = useParams();
  const term_params = params.term;
  useEffect(() => {
    get_inventario_search(term_params);
  }, [term_params]);

  const handleChangePlanta = (e) => {
    setPlanta(e.target.value);
  };

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => (window.location.href = "/inventario-p03/" + term), 0.2);
    setTerm("");
  };
  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900">
              Inventario de Materiales y productos Planta 03.
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            <div className="">
              <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
                <form onSubmit={(e) => onSubmit(e)} className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <button
                      type="submit"
                      className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </button>
                    <input
                      id="search"
                      name="search"
                      required
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Buscar"
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="p-1.5 bg-white border border-[#0096C4] rounded"
              >
                <IconFilter stroke="#0096C4" />
              </div>
              <div
                tabIndex={0}
                className="dropdown-content z-[1] menu p-4 shadow bg-base-100 rounded-box w-52"
              >
                <div className="flex flex-col gap-y-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text">Tipo de Salida</span>
                    </div>
                    <select
                      onChange={handleChangePlanta}
                      value={planta}
                      className="select select-bordered w-full"
                    >
                      <option value="">Elige una opción</option>
                      <option value="Normal">Normal</option>
                      <option value="EQ">EQ</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {inventario && inventario.length > 0 ? (
        <InventarioListSearch
          inventario={inventario}
          get_inventario_search_page={get_inventario_search_page}
          count={count}
          search_term={term_params}

        />
      ) : (
        <div className="flex items-center justify-center h-96">
          <div className="w-10 h-10 border-t-2 border-b-2 border-[#0096C4] rounded-full animate-spin"></div>
        </div>
      )}
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  inventario: state.inventario.inventario_filtrado,
  count: state.inventario.count,
});

export default connect(mapStateToProps, {
  get_inventario_search,
})(SearchInventario);
