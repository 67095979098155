import ReferenciasCardHorizontal from "./ReferenciasCardHorizontal";


function ReferenciasListSearch( {referencia} ){

  return (
    <div className="overflow-hidden">
    <div className="relative overflow-x-auto">
      <table className="table text-gray-500">
        <thead className="text-xs text-gray-700 uppercase">
          <tr>
            <th scope="col" className="p-2"></th>
            <th scope="col" className="p-2">
              Envase
            </th>
            <th scope="col" className="p-2">
              Peso
            </th>
            <th scope="col" className="p-2">
              Etiqueta
            </th>
            <th scope="col" className="p-2">
              Codigo
            </th>
            <th scope="col" className="p-2">
              Creacion
            </th>
            <th scope="col" className="p-2">
              Actualizacion
            </th>
            <th scope="col" className="p-2">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {
            referencia && referencia && referencia.map((data, index) => (
              <ReferenciasCardHorizontal key={data.id} data={data} index={index} />
            ))
            }
        </tbody>
      </table>
    </div>
   
  </div>
  )
}

export default ReferenciasListSearch;