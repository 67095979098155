import React, { useState, useEffect } from "react";
import axios from "axios";
import Toast from "../../Alert";
import { CircleLoader } from "react-spinners";

function SalidaMarcadaModal({ show, handleClose, salidaId, refreshData }) {
  const [loading, setLoading] = useState(false);
  const [isMarcada, setIsMarcada] = useState(null); // Initialize to null to handle both true and false states
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (show) {
      setStep(1); // Reset to initial step whenever modal is shown
    }
  }, [show]);

  const onChange = (e) => {
    const value = e.target.value === "true";
    setIsMarcada(value);
  };

  const handleInitialSubmit = (e) => {
    e.preventDefault();
    setStep(2); // Move to the second verification step
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isMarcada) {
      handleClose();
      return;
    }
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const data = {
      marcada: isMarcada,
      modal: true,
    };
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/salidas/update/${salidaId}/`,
        data,
        config
      );

      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "¡Salida marcada correctamente!",
        });
        handleClose();
        refreshData();
      }
    } catch (err) {
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al marcar la salida.",
      });
    }
    setLoading(false);
  };

  const resetModal = () => {
    setIsMarcada(null);
    setStep(1);
    handleClose();
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-2 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-auto p-4 transition-all">
        <div className="flex justify-between items-center border-b pb-2">
          <h2 className="text-xl font-semibold">
            {step === 1 ? "Confirmación Inicial" : "Marcar Salida como Terminada"}
          </h2>
          <button onClick={resetModal} className="text-gray-600 hover:text-gray-900">&times;</button>
        </div>
        {step === 1 ? (
          <form onSubmit={handleInitialSubmit} className="pt-4">
            <div className="mb-4 text-center">
              <p className="text-sm text-gray-700">Para poder marcar la salida como terminada, debes confirmar que los valores están correctos. ¿Deseas continuar?</p>
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={resetModal} className="btn border bg-gray-500 text-white px-4 py-2 rounded-md mr-2">
                Cancelar
              </button>
              <button type="submit" className="btn border bg-blue-500 text-white px-4 py-2 rounded-md">
                Continuar
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={onSubmit} className="pt-4">
            <div className="mb-4 text-center">
              <label className="block text-sm font-medium text-gray-700 mb-2">¿Terminada?</label>
              <div className="mt-2 flex justify-center items-center space-x-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="marcada-true"
                    name="marcada"
                    value="true"
                    onChange={onChange}
                    className="mr-2"
                  />
                  <label htmlFor="marcada-true">Sí</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="marcada-false"
                    name="marcada"
                    value="false"
                    onChange={onChange}
                    className="mr-2"
                  />
                  <label htmlFor="marcada-false">No</label>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={resetModal}
                className="btn border bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
              >
                Cancelar
              </button>
              {loading ? (
                <div className="btn border bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md">
                  <CircleLoader loading={loading} size={25} color="#ffffff" />
                </div>
              ) : (
                <button type="submit" className="btn border bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">
                  Confirmar
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default SalidaMarcadaModal;
