import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img/logo-removebg-preview.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 20,
  },
  text: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginBottom: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  wrapper: {
    flexDirection: "column",
    border: "1px solid #676767",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  contentKey: {
    fontSize: 14,
    fontFamily: "Oswald",
  },
  contentValue: {
    fontSize: 14,
    fontFamily: "Times-Roman",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const formatDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  return (
    <>
      {data ? (
        <Document title={`Reporte de fecha ${formattedDate}`}>
          <Page style={styles.body} wrap>
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>{`Reporte de Producto`}</Text>
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Detalles del Producto</Text>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Nombre:</Text>
                <Text style={styles.contentValue}>{data.producto.nombre}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Referencia:</Text>
                <Text style={styles.contentValue}>{data.producto.referencia}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Descripción:</Text>
                <Text style={styles.contentValue}>{data.producto.descripcion}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Entradas:</Text>
                <Text style={styles.contentValue}>{data.entradas}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Salidas:</Text>
                <Text style={styles.contentValue}>{data.salidas}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Gastos de Produccion:</Text>
                <Text style={styles.contentValue}>{data.gastos_produccion}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Inventario:</Text>
                <Text style={styles.contentValue}>{data.inventario}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Costo Unitario:</Text>
                <Text style={styles.contentValue}>$ {data.costos_unitario}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Costo Total:</Text>
                <Text style={styles.contentValue}>$ {data.costos_total}</Text>
              </View>
            </View>
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        "No se pudieron obtener los datos del producto"
      )}
    </>
  );
}

export default MyDocument;
