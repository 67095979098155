import React from "react";
import { Link } from "react-router-dom";
import {
  IconBrandInstagram,
  IconBrandX,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandYoutube
} from "@tabler/icons-react";
import logo from "../../assets/img/logo-removebg-preview.png";

function Footer() {
  return (
    <footer className="py-12 bg-white dark:bg-[#333844]">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/3 flex justify-center lg:justify-start mb-4 lg:mb-0">
          <img src={logo} alt="logo" className="w-32 h-auto" />
        </div>
        <div className="w-full lg:w-1/3 text-center">
          <div className="flex justify-center gap-x-6 mb-4">
            <Link
              to="https://empaquetadoradelnorte.com"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              Inicio
            </Link>
            <Link
              to="https://empaquetadoradelnorte.com/nosotros"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              Nostros
            </Link>
            <Link
              to="https://empaquetadoradelnorte.com/servicios"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              Servicios
            </Link>
            <Link
              to="https://empaquetadoradelnorte.com/blog"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              Blog
            </Link>
          </div>
          <div className="flex justify-center gap-x-4 mb-4">
            <Link
              to="https://www.instagram.com/empanorte/"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              <IconBrandInstagram size={24} />
            </Link>
            <Link
              to="https://www.facebook.com/CIempanorte"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              <IconBrandFacebook size={24} />
            </Link>
            <Link
              to="#"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              <IconBrandX size={24} />
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCJCsoq5y3a-k-VeCjKJr9Gw"
              className="text-slate-400 dark:text-gray-400"
              target="_blank"
            >
              <IconBrandYoutube size={24} />
            </Link>
          </div>
          <p className="text-slate-400 dark:text-gray-400">
            Copyright © {new Date().getFullYear()} Empanorte.
          </p>
        </div>
        <div className="w-full lg:w-1/3"></div>
      </div>
    </footer>
  );
}

export default Footer;
