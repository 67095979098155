import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function InventarioCardHorizontal({ data, index }) {
  const user = useSelector((state) => state.auth.user);
  return (
    <tr className="border-b border-red-600">
      <td className="p-2 dark:text-white"></td>
      <td className="p-2 dark:text-white">
        <div className="flex text-xs">
          <span className="font-bold">Insumo: </span>
          <span className="flex ml-1">{data.producto.nombre}</span>
        </div>
        <div className="flex text-xs">
          <span className="font-bold">Referencia: </span>
          <span className="flex ml-1">{data.producto.referencia}</span>
        </div>
        <div className="flex text-xs">
          <span className="font-bold">Descripcion: </span>
          <span className="flex ml-1">{data.producto.descripcion}</span>
        </div>
      </td>

      <td className="p-2 dark:text-white">
      {
        data.entradas ? data.entradas : 0
      }
      </td>
      <td className="p-2 dark:text-white">
       {
        data.salidas ? data.salidas : 0
       }
      </td>
      <td className="p-2 dark:text-white">
        {
          data.gastos_produccion ? data.gastos_produccion : 0
        }
      </td>
      <td className="p-2 dark:text-white">
      {
        data.inventario ? data.inventario : 0
      }
      </td>
      <td className="p-2 dark:text-white">{
        data.costos_unitario ? <>$ {data.costos_unitario}</> : 0
      }</td>

      <td className="p-2 dark:text-white">{
        data.costos_total ? <>$ {data.costos_total}</> : 0
      }</td>

      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">
         {
          user && user.role === "COORDINADOR_INVENTARIOS" || user && user.role === "COORDINADOR_PLANTA_ENVASADO" ? (
            <div className="flex tooltip" data-tip="Editar">
              <Link
                to={`/inventario-p03/editar/${data.id}`}
                className="p-1 text-white bg-yellow-500 rounded-full"
              >
                <IconPencil />
              </Link>
            </div>
          ) : null
         }

          <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/inventario-p03/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default InventarioCardHorizontal;
