import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ children }) {
const auth = JSON.parse(localStorage.getItem("auth"));
if (!auth) {
    return <Navigate to="/" />;
}
return children;
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PrivateRoute);
