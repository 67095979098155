import { useParams } from "react-router-dom";
import { get_averias_produccion } from "../../../redux/actions/P03/p03";
import { PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { useEffect } from "react";
import MyDocument from "../../../components/pdf/P03/averias/ReportAveriasProduccion";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";

function AveriasProduccionReportePdf({get_averias_produccion, averias}){
  const params = useParams();
  const uid  = params.uid;

	useEffect(() => {
		get_averias_produccion(uid);
	},[uid]);
  
	return (
    <DashboardLayout>
      <div className="container mx-auto max-w-5xl">
				{averias && averias ? (
					<>
						<div className="">
							<PDFViewer className="w-full h-[75vh] md:h-[85vh]">
								<MyDocument data={averias} />
							</PDFViewer>
						</div>
					</>
				) : (
					<div className="flex items-center justify-center h-96">
						<div className="w-10 h-10 border-t-2 border-b-2 border-[#0096C4] rounded-full animate-spin"></div>
					</div>
				)}
			</div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
	averias: state.averiasProduccion.averias_produccion,
});

export default connect(mapStateToProps, { get_averias_produccion })(AveriasProduccionReportePdf);