import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { useEffect } from "react";
import { get_salida_insumos } from "../../../redux/actions/P03/p03";
import MyDocument from "../../../components/pdf/P03/salidainsumos/SalidaInsumosPDF";

function SalidaInsumoReport({ get_salida_insumos, salida_insumos }) {
  const params = useParams();
  const uid = params.uid;

  useEffect(() => {
    get_salida_insumos(uid);
  }, [uid]);

  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Reporte de Salida de Materiales e Insumos Planta 03.
            </h3>
          </div>
        </div>
        <div className="mt-10 container mx-auto max-w-5xl">
          {salida_insumos ? (
            <div className="">
              <PDFViewer className="w-full h-[75vh] md:h-[85vh]">
                <MyDocument data={salida_insumos} />
              </PDFViewer>
            </div>
          ) : (
            <div className="flex items-center justify-center h-96">
              <div className="w-10 h-10 border-t-2 border-b-2 border-[#0096C4] rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  salida_insumos: state.salidaInsumos.salida_insumo,
});

export default connect(mapStateToProps, { get_salida_insumos })(
  SalidaInsumoReport
);
