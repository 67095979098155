import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="py-4">
      <div className="w-full px-6 mx-auto">
        <div className="flex flex-wrap items-center -mx-3 lg:justify-between">
          <div className="w-full max-w-full px-3 mt-0 mb-6 shrink-0 lg:mb-0 lg:w-1/2 lg:flex-none">
            <div className="text-sm leading-normal text-center text-slate-500 dark:text-white lg:text-left">
              © {new Date().getFullYear()}, desarrollado <i className="fa fa-heart"></i> por
              <Link to="#" className="font-poppins-semibold text-slate-700 dark:text-white"> Aspai</Link>
            </div>
          </div>
          {/* <div className="w-full max-w-full px-3 mt-0 shrink-0 lg:w-1/2 lg:flex-none">
            <ul className="flex flex-wrap justify-center pl-0 mb-0 list-none lg:justify-end">
              <li className="nav-item">
                <Link to="#" className="block px-4 pt-0 pb-1 text-sm font-normal transition-colors ease-soft-in-out text-slate-500 dark:text-white">Creative Tim</Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="block px-4 pt-0 pb-1 text-sm font-normal transition-colors ease-soft-in-out text-slate-500 dark:text-white">About Us</Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="block px-4 pt-0 pb-1 text-sm font-normal transition-colors ease-soft-in-out text-slate-500 dark:text-white">Blog</Link>
              </li>
              <li className="nav-item">
                <Link to="#" className="block px-4 pt-0 pb-1 pr-0 text-sm font-normal transition-colors ease-soft-in-out text-slate-500 dark:text-white">License</Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
