import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ReferenciasCardHorizontal({ data, index }) {
  const user = useSelector((state) => state.auth.user);

  return (
    <tr className="border-b">
      <td className="p-2 dark:text-white">{index + 1}</td>
      <td className="p-2 dark:text-white">{data.envase}</td>
      <td className="p-2 dark:text-white">{data.peso}</td>
      <td className="p-2 dark:text-white">{data.etiqueta}</td>
      <td className="p-2 dark:text-white">{data.codigo}</td>
      <td className="p-2 dark:text-white">
        {new Date(data.created_at).toLocaleDateString()}
      </td>
      <td className="p-2 dark:text-white">
        {new Date(data.updated_at).toLocaleDateString()}
      </td>
      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">
          {(user && user.role === "COORDINADOR_PRODUCCION_ENVASADO") ||
          (user && user.role === "COORDINADOR_PLANTA_ENVASADO") ? (
            <>
              <div className="flex tooltip" data-tip="Editar">
                <Link
                  to={`/referencias-p03/editar/${data.id}`}
                  className="p-1 text-white bg-yellow-500 rounded-full"
                >
                  <IconPencil />
                </Link>
              </div>
              <div className="flex tooltip" data-tip="Eliminar">
                <button className="p-1 text-white bg-red-500 rounded-full">
                  <IconTrash />
                </button>
              </div>
            </>
          ) : (
          //  sin acciones para el usuario un label
            <span className="p-1 text-black ">Sin Acciones</span>

          )}

          {/* <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/referencias-p03/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div> */}
        </div>
      </td>
    </tr>
  );
}

export default ReferenciasCardHorizontal;
