import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function HistorialCardHorizontal({ data, index }) {
  const user = useSelector((state) => state.auth.user);
  return (
    <tr className="bg-white border-b dark:bg-gray-800">
      <td className="p-2 dark:text-white"></td>
      <td className="p-2 dark:text-white">{data.fecha_modificacion}</td>
      <td className="p-2 dark:text-white">{data.tipo_cambio}</td>
      <td className="p-2 dark:text-white">{data.producto}</td>
      <td className="p-2 dark:text-white">{data.cambios}</td>
      <td className="p-2 dark:text-white">{data.user.first_name + " " + data.user.last_name }</td>
      <td className="p-2 dark:text-white">{data.user.plant}</td>

      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">

          <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/historial/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default HistorialCardHorizontal;
