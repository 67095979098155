import Footer from "../../components/auth/Footer";
import NavBar from "../../components/auth/NavBar";
import { Link } from "react-router-dom";

function Error404auth() {
  return (
    <>
      <NavBar />
      <section className="my-24 lg:my-48">
        <div className="container">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full max-w-full px-3 my-auto shrink-0 lg:flex-0 lg:w-6/12">
              <h1 className="leading-tight text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-tl from-red-600 to-rose-400 relative xl:text-6xl">
                Error 404
              </h1>
              <h2 className="mt-4 text-xl">Erm... Página no encontrada</h2>
              <p className="mt-2 font-normal leading-relaxed text-lg">
                Te sugerimos que vayas a la página de inicio mientras solucionamos este problema.
              </p>
              <Link
                to="/"
                className="inline-block px-6 py-3 mt-6 mb-4 font-bold text-center text-white uppercase transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-gray-900 to-slate-800 hover:scale-105 active:opacity-85 hover:shadow-soft-xs leading-tight text-sm ease-soft-in tracking-tight-soft shadow-soft-md"
              >
                Ir al inicio
              </Link>
            </div>
            <div className="relative w-full max-w-full px-3 my-auto shrink-0 lg:flex-0 lg:w-6/12">
              <img
                className="relative w-full"
                src="https://demos.creative-tim.com/soft-ui-dashboard-pro/assets/img/illustrations/error-404.png"
                alt="Imagen de error 404"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Error404auth;
