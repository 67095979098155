import {
  GET_MODIFICACIONES_LIST_SUCCESS,
  GET_MODIFICACIONES_LIST_FAIL,
  GET_MODIFICACIONES_SUCCESS,
  GET_MODIFICACIONES_FAIL,
  GET_MODIFICACIONES_SEARCH_SUCCESS,
  GET_MODIFICACIONES_SEARCH_FAIL,
} from "../actions/modificaciones/types"

const initialState = {
  modificacion: null,
  modificaciones: null,
  modificacion_filtrada: null,
  count: null,
  next: null,
  previous: null,
};

export default function modificaciones(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MODIFICACIONES_LIST_SUCCESS:
      return {
        ...state,
        modificaciones: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_MODIFICACIONES_LIST_FAIL:
      return {
        ...state,
        modificaciones: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_MODIFICACIONES_SUCCESS:
      return {
        ...state,
        modificacion: payload.modificacion,
      };
    case GET_MODIFICACIONES_FAIL:
      return {
        ...state,
        modificacion: null,
      };
    case GET_MODIFICACIONES_SEARCH_SUCCESS:
      return {
        ...state,
        modificacion_filtrada: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_MODIFICACIONES_SEARCH_FAIL:
      return {
        ...state,
        modificacion_filtrada: null,
      };
    default:
      return state;
  }
}
