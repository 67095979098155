import { useState, useEffect } from "react";

function SetPagination({ list_page, count, type, search_term }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [listingsPerPage, setListingsPerPage] = useState(5); // Default to small

  useEffect(() => {
    if (type === "small") {
      setListingsPerPage(5);
    } else if (type === "medium") {
      setListingsPerPage(9);
    } else if (type === "large") {
      setListingsPerPage(18);
    }
  }, [type]);

  const totalPages = Math.ceil(count / listingsPerPage);

  const visitPage = (page) => {
    setCurrentPage(page);
    list_page(page, search_term); // Pasar la página y el término de búsqueda
  };

  const previous_number = () => {
    if (currentPage > 1) {
      visitPage(currentPage - 1);
    }
  };

  const next_number = () => {
    if (currentPage < totalPages) {
      visitPage(currentPage + 1);
    }
  };

  const getNumbers = () => {
    return (
      <div key="current" className={`hidden md:-mt-px md:flex`}>
        <div
          className={`cursor-pointer pt-4 px-4 inline-flex items-center text-sm font-poppins-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300`}
        >
          Página {currentPage} de {totalPages}
        </div>
      </div>
    );
  };

  return (
    <nav className="border-t dark:border-t-gray-700 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        <button
          onClick={previous_number}
          disabled={currentPage === 1}
          className="border-transparent pt-4 pr-1 inline-flex items-center text-sm font-poppins-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          Anterior
        </button>
      </div>

      {getNumbers()}

      <div className="-mt-px w-0 flex-1 flex justify-end">
        <button
          onClick={next_number}
          disabled={currentPage === totalPages}
          className="border-transparent pt-4 pl-1 inline-flex items-center text-sm font-poppins-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          Siguiente
        </button>
      </div>
    </nav>
  );
}

export default SetPagination;
