import { IconEye, IconPencil, IconSend } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import RecibidoModal from "./RecibidoModal"; // Asegúrate de importar el modal correctamente

function EntradasCardHorizontal({ data, index, refreshData }) {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <tr className="">
        <td className="p-2 dark:text-white"></td>
        <td className="p-2 dark:text-white">{data.fecha}</td>
        <td className="p-2 dark:text-white">{data.remision}</td>
        <td className="p-2 dark:text-white">{data.descripcion}</td>
        <td className="p-2 dark:text-white">
          {data.recepcionproducto_set &&
            data.recepcionproducto_set.map((producto, idx) => (
              <div key={idx} className="mb-2">
                <div className="flex text-xs">
                  <span className="font-bold">Nombre: </span>
                  <span className="flex ml-1">{producto.producto.nombre}</span>
                </div>
                <div className="flex text-xs">
                  <span className="font-bold">Cantidad: </span>
                  <span className="flex ml-1">{producto.cantidad}</span>
                </div>
              </div>
            ))}
        </td>
        <td className="p-2 dark:text-white">
          {data.planta === "Proveedores" ? (
            <div className="flex text-xs">
              <span className="font-bold">Proveedor: </span>
              <span className="flex ml-1">{data.proveedor}</span>
            </div>
          ) : (
            <div className="flex text-xs">
              <span className="font-bold">Planta: </span>
              <span className="flex ml-1">{data.planta}</span>
            </div>
          )}
        </td>
        <td className="p-2 dark:text-white">
          <div className="flex items-center gap-2">
            {data.recibido == false ? (
              <>
                <div className="flex tooltip" data-tip="Recibido">
                  <button
                    onClick={handleOpenModal}
                    className="p-1 text-white bg-green-500 rounded-full"
                  >
                    <IconSend />
                  </button>
                </div>
                <div className="flex tooltip" data-tip="Editar">
                  <Link
                    to={`/entradas-p03/editar/${data.id}`}
                    className="p-1 text-white bg-yellow-500 rounded-full"
                  >
                    <IconPencil />
                  </Link>
                </div>
              </>
            ) : null}

            <div className="flex tooltip" data-tip="Ver Pdf">
              <Link
                to={`/entradas-p03/ver/${data.id}`}
                className="p-1 text-white bg-blue-500 rounded-full"
              >
                <IconEye />
              </Link>
            </div>
          </div>
        </td>
      </tr>
      {showModal && (
        <RecibidoModal
          show={showModal}
          handleClose={handleCloseModal}
          entradaId={data.id}
          refreshData={refreshData} // Pasar la función de actualización al modal
        />
      )}
    </>
  );
}

export default EntradasCardHorizontal;
