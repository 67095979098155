import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../../../../assets/img/logo-removebg-preview.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Oswald',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Oswald',
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    marginBottom: 10,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 'auto',
    width: 100,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  sectionTitle: {
    fontSize: 18,
    fontFamily: 'Oswald',
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 14,
    fontFamily: 'Times-Roman',
    marginBottom: 5,
  },
  wrapper: {
    flexDirection: 'column',
    border: '1px solid #676767',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  contentKey: {
    fontSize: 14,
    fontFamily: 'Oswald',
  },
  contentValue: {
    fontSize: 14,
    fontFamily: 'Times-Roman',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
    backgroundColor: '#e0e0e0',
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: 'center',
    color: 'grey',
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const formatDateTime = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return new Intl.DateTimeFormat('es-ES', options).format(date);
};

function MyDocument({ data }) {
  console.log(data);
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  return (
    <>
      {data ? (
        <Document title={`Reporte de Producción - ${data.fecha}`}>
          <Page style={styles.body} wrap>
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>{`Reporte de Producción`}</Text>
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Detalles de la Producción</Text>

              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Fecha:</Text>
                <Text style={styles.contentValue}>{data.fecha}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Turno:</Text>
                <Text style={styles.contentValue}>{data.turno === 'A' ? 'Diurno' : 'Nocturno'}</Text>
              </View>
              {data.lote_soya && (
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey}>Lote de Soya:</Text>
                  <Text style={styles.contentValue}>{data.lote_soya}</Text>
                </View>
              )}
              {data.fv_soya && (
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey}>Fecha de Vencimiento de Soya:</Text>
                  <Text style={styles.contentValue}>{data.fv_soya}</Text>
                </View>
              )}
              {data.lote_palma && (
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey}>Lote de Palma:</Text>
                  <Text style={styles.contentValue}>{data.lote_palma}</Text>
                </View>
              )}
              {data.fv_palma && (
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey}>Fecha de Vencimiento de Palma:</Text>
                  <Text style={styles.contentValue}>{data.fv_palma}</Text>
                </View>
              )}
              {data.lote_mezcla && (
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey}>Lote de Mezcla:</Text>
                  <Text style={styles.contentValue}>{data.lote_mezcla}</Text>
                </View>
              )}
              {data.fv_mezcla && (
                <View style={styles.contentRow}>
                  <Text style={styles.contentKey}>Fecha de Vencimiento de Mezcla:</Text>
                  <Text style={styles.contentValue}>{data.fv_mezcla}</Text>
                </View>
              )}
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Tipo de Producción:</Text>
                <Text style={styles.contentValue}>{data.tipo_produccion}</Text>
              </View>
            </View>
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Referencias</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Código</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Etiqueta</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Cantidad</Text>
                  </View>
                </View>
                {data.produccionreferencia_set && data.produccionreferencia_set.map((ref, index) => (
                  <View style={styles.tableRow} key={index}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {ref.referencia.codigo}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {ref.referencia.etiqueta}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{ref.cantidad}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        'No se pudieron obtener los datos del reporte'
      )}
    </>
  );
}

export default MyDocument;
