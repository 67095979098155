import { Route, Router, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Error404 from "../../containers/error/Error404";
import Login from "../../containers/auth/Login";
import ForgotPassword from "../../containers/auth/ForgotPassword";
import Dashboard from "../../containers/Dashboard";
import PrivateRoute from "./PrivateRoutes";
import PasswordConfirm from "../../containers/auth/PasswordConfirm";
import Register from "../../containers/auth/Register";
import TokenVerify from "../../containers/auth/Token";


// Planta 03
// Entradas
import EntradasP03 from "../../containers/P03/entradas/EntradasP03";
import EntradasP03Form from "../../containers/P03/entradas/EntradasP03Form";
import EntradasP03Editar from "../../containers/P03/entradas/EntradasP03Editar";
import SearchEntradasP03 from "../../containers/P03/entradas/SearchEntradasP03";
import EntradasReportePdf from "../../containers/P03/entradas/EntradasReportePdf";
import EntradasReportePdfMensual from "../../containers/P03/entradas/EntradasReportePdfMensual";
// Salidas
import SalidasP03 from "../../containers/P03/salidas/SalidasP03";
import SalidasP03Form from "../../containers/P03/salidas/SalidasP03Form";
import SalidasP03Edit from "../../containers/P03/salidas/SalidasP03Edit";
import SalidasReportePdf from "../../containers/P03/salidas/SalidasReportePdf";
import SearchSalidasP03 from "../../containers/P03/salidas/SearchSalidasP03";
// Referencias
import ReferenciasP03 from "../../containers/P03/referencias/ReferenciasP03";
import ReferenciasP03Form from "../../containers/P03/referencias/ReferenciasP03Form";
import ReferenciasP03Edit from "../../containers/P03/referencias/ReferenciasP03Edit";
import SearchReferenciasP03 from "../../containers/P03/referencias/SearchReferenciasP03";
// Produccion
import ProduccionP03 from "../../containers/P03/produccion/ProduccionP03";
import ProduccionP03Form from "../../containers/P03/produccion/ProduccionP03Form";
import ProduccionP03Edit from "../../containers/P03/produccion/ProduccionP03Edit";
import ProduccionReportePdf from "../../containers/P03/produccion/ProduccionReportePdf";
// Averias
import AveriasProduccionP03 from "../../containers/P03/averias/AveriasProduccionP03";
import AveriasProduccionReportePdf from "../../containers/P03/averias/AveriasProduccionReportePdf";
// Inventario
import InventarioP03 from "../../containers/P03/invetario/InventarioP03";
import SearchInventarioP03 from "../../containers/P03/invetario/SearchInventarioP03";
import InventarioP03Edit from "../../containers/P03/invetario/InventarioP03Edit";
import ReporteMaterialesInsumos from "../../containers/P03/invetario/ReporteMaterialesInsumos";
// Saldo Planta
import SaldoPlantaP03 from "../../containers/P03/saldoPlanta/SaldoPlantaP03";
// Salida de Insumos
import SalidasInsumosP03 from "../../containers/P03/salidasinsumos/SalidasInsumosP03";
import SalidaInsumosP03Form from "../../containers/P03/salidasinsumos/SalidaInsumosP03Form";
import SalidaInsumoP03Edit from "../../containers/P03/salidasinsumos/SalidaInsumoP03Edit";
import SalidaInsumoReport from "../../containers/P03/salidasinsumos/SalidaInsumoReport";
import SalidaInsumosReportePdfMensual from "../../containers/P03/salidasinsumos/SalidaInsumosReportePdfMensual";
import Error404auth from "../../containers/error/Error404auth";
// Modificaciones
import Modificaciones from "../../containers/Modificaciones/Modificaciones";
import ModificacionForm from "../../containers/Modificaciones/ModificacionesForm";
import ModificacionesEdit from "../../containers/Modificaciones/ModificacionesEdit";
import ModificacionesReportePdf from "../../containers/Modificaciones/ModificacionesReportePdf";

// Historial
import Historial from "../../containers/historial/Historial";
import HistorialReportePdf from "../../containers/historial/HistorialReportePdf";
import SearchSalidasInsumos from "../../containers/P03/salidasinsumos/SearchSalidasInsumos";
import ModificacionesSearch from "../../containers/Modificaciones/ModificacionesSearch";


function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
      <Route
        path="*"
        element={
          JSON.parse(localStorage.getItem("auth")) ? (
            <Error404 />
          ) : (
            <Error404auth/>
          )
        }
      />
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password/reset/confirm/:uid/:token"element={<PasswordConfirm />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* Historial */}
        <Route
          path="/historial"
          element={
            <PrivateRoute>
              <Historial />
            </PrivateRoute>
          }
        />
        <Route
        path="/historial/ver/:uid"
        element={
          <PrivateRoute>
            <HistorialReportePdf />
          </PrivateRoute>
        }
        />
        {/* Modificaciones */}
        <Route
          path="/modificaciones"
          element={
            <PrivateRoute>
              <Modificaciones />
            </PrivateRoute>
          }
        />
        <Route
          path="/modificaciones/form"
          element={
            <PrivateRoute>
              <ModificacionForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/modificacion/editar/:uid"
          element={
            <PrivateRoute>
              <ModificacionesEdit />
            </PrivateRoute>
          }
        />
        <Route 
        path="/modificaciones/ver/:uid"
        element={
          <PrivateRoute>
            <ModificacionesReportePdf />
          </PrivateRoute>
        }
        />
        <Route path="/modificaciones/:search_term" element={<PrivateRoute><ModificacionesSearch /></PrivateRoute>} />
        {/* Planta 03 */}
        {/* Entradas */}
        <Route
          path="entradas-p03"
          element={
            <PrivateRoute>
              <EntradasP03 />
            </PrivateRoute>
          }
        />

        <Route
          path="entradas-p03/form"
          element={
            <PrivateRoute>
              <EntradasP03Form />
            </PrivateRoute>
          }
        />

        <Route
          path="entradas-p03/editar/:uid"
          element={
            <PrivateRoute>
              <EntradasP03Editar />
            </PrivateRoute>
          }
        />

        <Route
          path="entradas-p03/:term"
          element={
            <PrivateRoute>
              <SearchEntradasP03 />
            </PrivateRoute>
          }
        />

        <Route
          path="entradas-p03/ver/:uid"
          element={
            <PrivateRoute>
              <EntradasReportePdf />
            </PrivateRoute>
          }
        />

        <Route
          path="entradas-p03/ver-mensual/:term/:term2/:term3"
          element={
            <PrivateRoute>
              <EntradasReportePdfMensual />
            </PrivateRoute>
          }
        />

        {/* Salidas planta 03 */}
        <Route
          path="salidas-p03"
          element={
            <PrivateRoute>
              <SalidasP03 />
            </PrivateRoute>
          }
        />

        <Route
          path="salidas-p03/form"
          element={
            <PrivateRoute>
              <SalidasP03Form />
            </PrivateRoute>
          }
        />

        <Route
          path="salidas-p03/editar/:uid"
          element={
            <PrivateRoute>
              <SalidasP03Edit />
            </PrivateRoute>
          }
        />

        <Route
          path="salidas-p03/ver/:uid"
          element={
            <PrivateRoute>
              <SalidasReportePdf />
            </PrivateRoute>
          }
        />

        <Route 
        path="salidas-p03/:term"
        element={
          <PrivateRoute>
            <SearchSalidasP03 />
          </PrivateRoute>
        }
        />
        {/* Salidas Insumos */}
        <Route
        path="salida-insumos-p03"
        element={
          <PrivateRoute>
            <SalidasInsumosP03 />
          </PrivateRoute>
        }
        />
        <Route
        path="salida-insumos-p03/:term"
        element={
          <PrivateRoute>
            <SearchSalidasInsumos />
          </PrivateRoute>
        }
        />
        <Route
        path="salida-insumos-p03/form"
        element={
          <PrivateRoute>
            <SalidaInsumosP03Form />
          </PrivateRoute>
        }
        />
        <Route
        path="salida-insumos-p03/editar/:uid"
        element={
          <PrivateRoute>
            <SalidaInsumoP03Edit />
          </PrivateRoute>
        }
        />
        <Route
        path="salida-insumos-p03/ver/:uid"
        element={
          <PrivateRoute>
            <SalidaInsumoReport />
          </PrivateRoute>
        }
        />
        <Route
        path="salida-insumos-p03/ver-mensual/:term/:term2/:term3"
        element={
          <PrivateRoute>
            <SalidaInsumosReportePdfMensual />
          </PrivateRoute>
        }
        />
        {/* Referencias */}

        <Route
          path="referencias-p03"
          element={
            <PrivateRoute>
              <ReferenciasP03 />
            </PrivateRoute>
          }
        />

        <Route
          path="referencias-p03/form"
          element={
            <PrivateRoute>
              <ReferenciasP03Form />
            </PrivateRoute>
          }
        />

        <Route
          path="referencias-p03/editar/:uid"
          element={
            <PrivateRoute>
              <ReferenciasP03Edit />
            </PrivateRoute>
          }
        />
        <Route
        path="referencias-p03/:term"
        element={
          <PrivateRoute>
            <SearchReferenciasP03 />
          </PrivateRoute>
        }
        />


        {/* Produccion */}

        <Route
        path="produccion-p03"
        element={
          <PrivateRoute>
            <ProduccionP03 />
          </PrivateRoute>
        }
        />

        <Route
        path="produccion-p03/form"
        element={
          <PrivateRoute>
            <ProduccionP03Form />
          </PrivateRoute>
        }
        />
        <Route
        path="produccion-p03/editar/:uid"
        element={
          <PrivateRoute>
            <ProduccionP03Edit />
          </PrivateRoute>
        }
        />

        <Route 
        path="produccion-p03/ver/:uid"
        element={
          <PrivateRoute>
            <ProduccionReportePdf />
          </PrivateRoute>
        }
        />
        {/* Inventario */}

        <Route
        path="inventario-p03"
        element={
          <PrivateRoute>
            <InventarioP03 />
          </PrivateRoute>
        }
        />

        <Route
        path="inventario-p03/:term"
        element={
          <PrivateRoute>
            <SearchInventarioP03 />
          </PrivateRoute>
        }
        />

        <Route
        path="inventario-p03/editar/:uid"
        element={
          <PrivateRoute>
            <InventarioP03Edit />
          </PrivateRoute>
        }
        />
        <Route  
        path="inventario-p03/ver/:uid"
        element={
          <PrivateRoute>
            <ReporteMaterialesInsumos />
          </PrivateRoute>
        }
        />
        {/* Saldos Planta */}
        <Route
        path="saldo-planta-p03"
        element={
          <PrivateRoute>
            <SaldoPlantaP03 />
          </PrivateRoute>
        }
        />
        {/* Averias produccion */}

        <Route
        path="averias-produccion-p03"
        element={
          <PrivateRoute>
            <AveriasProduccionP03 />
          </PrivateRoute>
        }
        />

        <Route
        path="averias-produccion-p03/ver/:uid"
        element={
          <PrivateRoute>
            <AveriasProduccionReportePdf />
          </PrivateRoute>
        }
        />
      </Routes>
    </AnimatePresence>
  );
}
export default AnimatedRoutes;
