import React, { useState, useEffect } from "react";
import axios from "axios";
import Toast from "../../Alert";

const ProductoModal = ({ show, handleClose, isEditMode, editData, refreshProductos }) => {
  const [insumo, setInsumo] = useState("");
  const [referencia, setReferencia] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [referencias, setReferencias] = useState([]);
  const [allInsumos, setAllInsumos] = useState([]);
  const [allReferencias, setAllReferencias] = useState({});
  const [isReferenciaDisabled, setIsReferenciaDisabled] = useState(true);

  // Actualizar el formulario cuando se entra en modo de edición
  useEffect(() => {
    if (isEditMode && editData) {
      setInsumo(editData.insumo);
      setReferencia(editData.referencia);
      setDescripcion(editData.descripcion);
    } else {
      setInsumo("");
      setReferencia("");
      setDescripcion("");
    }
  }, [isEditMode, editData]);

  // Inicializar insumos y referencias
  useEffect(() => {
    const fetchData = async () => {
      const insumos = {
        "Aceite": [
          { referencia: "Palma", descripcion: "Kilos" },
          { referencia: "Soya", descripcion: "Kilos" },
          { referencia: "Mezcla 95/05 MA", descripcion: "95% Palma/05 % Soya" }
        ],
        "Almidon": [
          { referencia: "Bultos de Almidon", descripcion: "Bolsas de papel" }
        ],
        "Asas": [
          { referencia: "38 MM", descripcion: "5 Litros" }
        ],
        "Corrugado": [
          { referencia: "Sin Código", descripcion: "200 ML" },
          { referencia: "250311", descripcion: "250 ML" },
          { referencia: "250312", descripcion: "430/450 ML" },
          { referencia: "250313", descripcion: "500 ML" },
          { referencia: "250314", descripcion: "710 ML" },
          { referencia: "250315", descripcion: "850 ML" },
          { referencia: "250316", descripcion: "900/1000 ML EQ" },
          { referencia: "250317", descripcion: "900/1000 ML FK" },
          { referencia: "250318", descripcion: "2800/3000 ML" },
          { referencia: "250319", descripcion: "3000 ML" },
          { referencia: "250320", descripcion: "5000 ML FK" },
          { referencia: "250321", descripcion: "2000 ML" },
          { referencia: "250322", descripcion: "3000 ML" },
          { referencia: "250323", descripcion: "900/1000 ML Lisa" },
          { referencia: "250325", descripcion: "5000 ML Lisa" },
          { referencia: "250326", descripcion: "430/450 ML Lisa" },
          { referencia: "124637-1", descripcion: "900 ML Sonelo" },
          { referencia: "124619-1", descripcion: "2000 ML Sonelo" },
          { referencia: "124639-1", descripcion: "3000 ML Sonelo" }
        ],
        "Disposiciones": [
          { referencia: "Desperdicio Planta", descripcion: "Aceite" }
        ],
        "Envase": [
          { referencia: "200 ML", descripcion: "Emplanorte" },
          { referencia: "250 ML", descripcion: "Patios/Chocolate" },
          { referencia: "430/450 ML", descripcion: "Chocolate" },
          { referencia: "500 ML", descripcion: "Chocolate" },
          { referencia: "700 GR", descripcion: "Chocolate" },
          { referencia: "710 ML", descripcion: "Chocolate" },
          { referencia: "850 ML", descripcion: "EQ" },
          { referencia: "900 ML", descripcion: "FK" },
          { referencia: "900 ML", descripcion: "Pescado" },
          { referencia: "900 ML", descripcion: "Chocolate" },
          { referencia: "1000 ML", descripcion: "FK" },
          { referencia: "1000 ML", descripcion: "Pescado" },
          { referencia: "1000 ML", descripcion: "Chocolate" },
          { referencia: "2000 ML", descripcion: "Chocolate" },
          { referencia: "2800 ML", descripcion: "Chocolate EQ" },
          { referencia: "2800 ML", descripcion: "Chocolate FK" },
          { referencia: "3000 ML", descripcion: "Chocolate EQ" },
          { referencia: "3000 ML", descripcion: "Chocolate FK" },
          { referencia: "5000 ML", descripcion: "Chocolate EQ" },
          { referencia: "5000 ML", descripcion: "Chocolate FK" },
          { referencia: "20 litros", descripcion: "Bidones" }
        ],
        "Etiqueta": [
          { referencia: "500 ML", descripcion: "Arduvi Soya" },
          { referencia: "710 ML", descripcion: "Arduvi Soya" },
          { referencia: "800 ML", descripcion: "Arduvi Soya" },
          { referencia: "850 ML", descripcion: "Arduvi Soya" },
          { referencia: "900 ML", descripcion: "Arduvi Soya" },
          { referencia: "1000 ML", descripcion: "Arduvi Soya" },
          { referencia: "2000 ML", descripcion: "Arduvi Soya" },
          { referencia: "3000 ML", descripcion: "Arduvi Soya" },
          { referencia: "5000 ML", descripcion: "Arduvi Soya" },
          { referencia: "250 ML", descripcion: "Arduvi Palma" },
          { referencia: "450 ML", descripcion: "Arduvi Palma" },
          { referencia: "500 ML", descripcion: "Arduvi Palma" },
          { referencia: "710 ML", descripcion: "Arduvi Palma" },
          { referencia: "850 ML", descripcion: "Arduvi Palma" },
          { referencia: "900 ML", descripcion: "Arduvi Palma" },
          { referencia: "1000 ML", descripcion: "Arduvi Palma" },
          { referencia: "2000 ML", descripcion: "Arduvi Palma" },
          { referencia: "2800 ML", descripcion: "Arduvi Palma" },
          { referencia: "3000 ML", descripcion: "Arduvi Palma" },
          { referencia: "5000 ML", descripcion: "Arduvi Palma" },
          { referencia: "20 litros", descripcion: "Arduvi Palma" },
          { referencia: "3000 ML", descripcion: "Santandereana Palma" },
          { referencia: "900 ML", descripcion: "Imperial Soya" },
          { referencia: "1000 ML", descripcion: "Imperial Soya" },
          { referencia: "200 ML", descripcion: "Oliverde Soya" },
          { referencia: "250 ML", descripcion: "Oliverde Soya" },
          { referencia: "430 ML", descripcion: "Oliverde Soya" },
          { referencia: "500 ML", descripcion: "Oliverde Soya" },
          { referencia: "710 ML", descripcion: "Oliverde Soya" },
          { referencia: "760 ML", descripcion: "Oliverde Soya" },
          { referencia: "800 ML", descripcion: "Oliverde Soya" },
          { referencia: "825 ML", descripcion: "Oliverde Soya" },
          { referencia: "850 ML", descripcion: "Oliverde Soya" },
          { referencia: "900 ML", descripcion: "Oliverde Soya" },
          { referencia: "1000 ML", descripcion: "Oliverde Soya" },
          { referencia: "2800 ML", descripcion: "Oliverde Soya" },
          { referencia: "3000 ML", descripcion: "Oliverde Soya" },
          { referencia: "5000 ML", descripcion: "Oliverde Soya" },
          { referencia: "20 litros", descripcion: "Oliverde Soya" },
          { referencia: "430 ML", descripcion: "Arduvi Mezcla" },
          { referencia: "900 ML", descripcion: "Arduvi Mezcla" },
          { referencia: "3000 ML", descripcion: "Arduvi Mezcla" },
          { referencia: "2700 ML", descripcion: "Del Catatumbo Mezcla" },
          { referencia: "900 ML", descripcion: "Molinera Mezcla" },
          { referencia: "3000 ML", descripcion: "Molinera Mezcla" },
          { referencia: "710 ML", descripcion: "Oliverde Mezcla" },
          { referencia: "900 ML", descripcion: "Sonelo CC-MA" },
          { referencia: "2000 ML", descripcion: "Sonelo CC-MA" },
          { referencia: "3000 ML", descripcion: "Sonelo CC-MA" },
          { referencia: "900 ML", descripcion: "Sonelo CM-MA" },
          { referencia: "2000 ML", descripcion: "Sonelo CM-MA" },
          { referencia: "3000 ML", descripcion: "Sonelo CM-MA" },
          { referencia: "900 ML", descripcion: "Sonelo CF-MC" },
          { referencia: "2000 ML", descripcion: "Sonelo CF-MC" },
          { referencia: "3000 ML", descripcion: "Sonelo CF-MC" },
          { referencia: "2000 ML", descripcion: "Oliverde Soya" },
          { referencia: "430 ML", descripcion: "PE Mezcla de Aceite Vegetales" },
          { referencia: "900 ML", descripcion: "PE Mezcla de Aceite Vegetales" },
          { referencia: "1000 ML", descripcion: "PE Mezcla de Aceite Vegetales" },
          { referencia: "2800 ML", descripcion: "PE Mezcla de Aceite Vegetales" },
          { referencia: "3000 ML", descripcion: "PE Mezcla de Aceite Vegetales" },
          { referencia: "5000 ML", descripcion: "PE Mezcla de Aceite Vegetales" }
        ],
        "Stickers": [
          { referencia: "900 ML", descripcion: "Codigo de Barras Arduvi Palma" },
          { referencia: "3000 ML", descripcion: "Codigo de Barras Arduvi Palma" },
          { referencia: "900 ML", descripcion: "Codigo de Barras Arduvi Mezcla" },
          { referencia: "3000 ML", descripcion: "Codigo de Barras Arduvi Mezcla" },
          { referencia: "900 ML", descripcion: "Codigo de Barras Molinera Mezcla" },
          { referencia: "3000 ML", descripcion: "Codigo de Barras Molinera Mezcla" },
          { referencia: "900 ML", descripcion: "Codigo de Barras Sonelo CC-MA" },
          { referencia: "2000 ML", descripcion: "Codigo de Barras Sonelo CC-MA" },
          { referencia: "3000 ML", descripcion: "Codigo de Barras Sonelo CC-MA" },
          { referencia: "900 ML", descripcion: "Codigo de Barras Sonelo CM-MB" },
          { referencia: "2000 ML", descripcion: "Codigo de Barras Sonelo CM-MB" },
          { referencia: "3000 ML", descripcion: "Codigo de Barras Sonelo CM-MB" },
          { referencia: "900 ML", descripcion: "Codigo de Barras Sonelo CF-MC" },
          { referencia: "2000 ML", descripcion: "Codigo de Barras Sonelo CF-MC" },
          { referencia: "3000 ML", descripcion: "Codigo de Barras Sonelo CF-MC" }
        ],
        "Tapas": [
          { referencia: "28 MM", descripcion: "Cuello Corto Blanca" },
          { referencia: "65 MM", descripcion: "Bidones" },
          { referencia: "38 MM", descripcion: "5 Litros" }
        ],
        "Tapon": [
          { referencia: "58 MM", descripcion: "Bidones" }
        ],
        "Termoencogible": [
          { referencia: "Rollos de Plastico", descripcion: "Vinipel para estibas" }
        ]
      };
  
      setAllInsumos(Object.keys(insumos));
      setAllReferencias(insumos);
    };
  
    fetchData();
  }, []);

  // Actualizar referencias al cambiar insumo
  useEffect(() => {
    if (insumo) {
      setReferencias(allReferencias[insumo]);
      setIsReferenciaDisabled(false);
      setReferencia("");  // Limpiar referencia seleccionada
      setDescripcion(""); // Limpiar descripción
    } else {
      setReferencias([]);
      setIsReferenciaDisabled(true);
      setReferencia("");
      setDescripcion("");
    }
  }, [insumo, allReferencias]);

  // Actualizar la descripción al cambiar referencia
  const handleReferenciaChange = (e) => {
    const ref = e.target.value;
    setReferencia(ref);

    const selectedReferencia = referencias.find(r => r.referencia === ref);
    if (selectedReferencia) {
      setDescripcion(selectedReferencia.descripcion); // Actualizar la descripción automáticamente
    } else {
      setDescripcion("");
    }
  };

  const handleSave = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const data = JSON.stringify({
      nombre: insumo,
      referencia: referencia,
      descripcion: descripcion
    });

    try {
      if (isEditMode) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/productos/update/${editData.id}/`,
          data,
          config
        );
        Toast({ tipo: "success", mensaje: "¡Producto actualizado correctamente!" });
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/productos/create/`,
          data,
          config
        );
        Toast({ tipo: "success", mensaje: "¡Producto creado correctamente!" });
      }
      refreshProductos();
      handleClose();
    } catch (error) {
      Toast({ tipo: "error", mensaje: "Ocurrió un error al guardar el producto." });
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-lg mx-2">
        <div className="px-4 py-2 flex justify-between items-center border-b dark:border-gray-600">
          <h2 className="text-xl font-semibold dark:text-white">
            {isEditMode ? "Editar Producto" : "Agregar Producto"}
          </h2>
          <button onClick={handleClose} className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">
            &times;
          </button>
        </div>
        <div className="px-4 py-4">
          <label htmlFor="insumo" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Insumo</label>
          <select
            id="insumo"
            name="insumo"
            value={insumo}
            onChange={(e) => setInsumo(e.target.value)}
            className="select select-bordered w-full dark:bg-gray-700 dark:text-white rounded-md shadow-sm"
          >
            <option value="">Seleccione un insumo</option>
            {allInsumos.map(insumo => (
              <option key={insumo} value={insumo}>{insumo}</option>
            ))}
          </select>
        </div>
        <div className="px-4 py-4">
          <label htmlFor="referencia" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Referencia</label>
          <select
            id="referencia"
            name="referencia"
            value={referencia}
            onChange={handleReferenciaChange}
            className="select select-bordered w-full dark:bg-gray-700 dark:text-white rounded-md shadow-sm"
            disabled={isReferenciaDisabled}
          >
            <option value="">Seleccione una referencia</option>
            {referencias.map(ref => (
              <option key={ref.referencia} value={ref.referencia}>{ref.referencia}</option>
            ))}
          </select>
        </div>
        <div className="px-4 py-4">
          <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Descripción</label>
          <input
            id="descripcion"
            name="descripcion"
            value={descripcion}
            disabled
            className="input input-bordered w-full dark:bg-gray-700 dark:text-white rounded-md shadow-sm"
          />
        </div>
        <div className="px-4 py-3 flex justify-end">
          <button onClick={handleClose} className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md mr-2">
            Cancelar
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md">
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductoModal;
