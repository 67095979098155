import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  get_entrada,
  get_productos_list,
} from "../../../redux/actions/P03/p03";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../../../components/Alert";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import ProductoModal from "../../../components/P03/entradas/ProductoModal";

function EntradasP03Editar({
  get_entrada,
  get_productos_list,
  entrada,
  productos,
}) {
  const params = useParams();
  const id = params.uid;
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);

  const dropdownRef = useRef(null);

  useEffect(() => {
    get_entrada(id);
    get_productos_list();
  }, [get_entrada, get_productos_list, id]);

  const [formData, setFormData] = useState({
    fecha: "",
    remision: "",
    descripcion: "",
    recepcionproducto_set: [],
    planta: "",
    proveedor: "",
  });

  useEffect(() => {
    if (entrada) {
      const recepcionproducto_set = entrada.recepcionproducto_set.map(
        (item) => ({
          producto: item.producto.id,
          cantidad: item.cantidad,
        })
      );
      setFormData({
        fecha: entrada.fecha,
        remision: entrada.remision,
        descripcion: entrada.descripcion,
        recepcionproducto_set,
        planta: entrada.planta,
        proveedor: entrada.proveedor,
      });
    }
  }, [entrada, productos]);

  const { fecha, remision, descripcion, planta, proveedor } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/entradas/update/${id}/`,
        formData,
        config
      );

      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "¡Entrada actualizada correctamente!",
        });
      }
    } catch (err) {
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al actualizar la entrada.",
      });
    }
    setLoading(false);
  };

  const openAddModal = () => {
    setIsEditMode(false);
    setEditData(null);
    setIsModalOpen(true);
  };

  const openEditModal = (producto) => {
    setIsEditMode(true);
    setEditData(producto);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const refreshProductos = () => {
    get_productos_list();
  };

  const addProducto = () => {
    if (formData.recepcionproducto_set.length < productos.length) {
      setFormData({
        ...formData,
        recepcionproducto_set: [
          ...formData.recepcionproducto_set,
          { producto: "", cantidad: "" },
        ],
      });
      setQuery("");
    }
  };

  const handleRemoveProducto = (index) => {
    const newProds = [...formData.recepcionproducto_set];
    newProds.splice(index, 1);
    setFormData({
      ...formData,
      recepcionproducto_set: newProds,
    });
  };

  const handleProductoChange = (index, field, value) => {
    const newProds = [...formData.recepcionproducto_set];
    newProds[index][field] = value;
    setFormData({
      ...formData,
      recepcionproducto_set: newProds,
    });
  };

  const filteredProductos = productos
    ? query === ""
      ? productos
      : productos.filter((producto) =>
          producto.nombre
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const selectedProductIds = formData.recepcionproducto_set.map(
    (p) => p.producto
  );
  const availableProductos = filteredProductos.filter(
    (producto) => !selectedProductIds.includes(producto.id)
  );
  const filteredProductosDropdown = availableProductos.slice(0, 10);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const today = new Date().toISOString().split("T")[0];

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Entrada.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la entrada.
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-white">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={fecha}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                disabled
                max={new Date().toISOString().split("T")[0]}
              />
            </div>

            <div className="form-control">
              <label htmlFor="remision" className="label">
                <span className="label-text dark:text-white">Remision</span>
              </label>
              <input
                type="text"
                name="remision"
                value={remision}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="descripcion" className="label">
                <span className="label-text dark:text-white">Descripcion</span>
              </label>
              <input
                type="text"
                name="descripcion"
                value={descripcion}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="planta" className="label">
                <span className="label-text dark:text-white">
                  Planta/Proveedor
                </span>
              </label>
              <select
                name="planta"
                value={planta}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Selecciona una planta o proveedor</option>
                <option value="1">Planta 1</option>
                <option value="2">Planta 2</option>
                <option value="3">Planta 3</option>
                <option value="4">Planta 4</option>
                <option value="Proveedores">Proveedor</option>
              </select>
            </div>

            {planta === "Proveedores" && (
              <div className="form-control">
                <label htmlFor="proveedor" className="label">
                  <span className="label-text dark:text-white">
                    Nombre del Proveedor
                  </span>
                </label>
                <input
                  type="text"
                  name="proveedor"
                  value={proveedor}
                  onChange={(e) => onChange(e)}
                  required
                  className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            )}
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Productos
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.recepcionproducto_set.map((prod, index) => (
              <div key={index} className="mb-3">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                  <div className="form-control">
                    <label htmlFor="producto" className="label">
                      <span className="label-text dark:text-white">
                        Producto
                      </span>
                    </label>
                    <div className="relative" ref={dropdownRef}>
                      <div className="inline-flex items-center justify-between w-full">
                        <button
                          type="button"
                          onClick={() => setDropdownOpen(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {prod.producto
                              ? productos.find((p) => p.id === prod.producto)
                                  ?.nombre
                              : "Seleccionar Producto"}
                          </span>
                          <IconEye className="ml-2" size={20} />
                        </button>
                      </div>
                      {dropdownOpen === index && (
                        <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                          <input
                            id="search-input"
                            className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
                            type="text"
                            placeholder="Buscar productos"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          <div className="max-h-60 overflow-y-auto">
                            {filteredProductosDropdown.length === 0 ? (
                              <div className="px-4 py-2 text-gray-700 dark:text-white">
                                No se encontraron productos
                              </div>
                            ) : (
                              filteredProductosDropdown.map((prodItem) => (
                                <div
                                  key={prodItem.id}
                                  onClick={() => {
                                    const newProds = [
                                      ...formData.recepcionproducto_set,
                                    ];
                                    newProds[index].producto = prodItem.id;
                                    setFormData({
                                      ...formData,
                                      recepcionproducto_set: newProds,
                                    });
                                    setDropdownOpen(null);
                                    setQuery("");
                                  }}
                                  className="cursor-pointer px-4 py-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                                >
                                  {prodItem.nombre}
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-control">
                    <label htmlFor="cantidad" className="label">
                      <span className="label-text dark:text-white">
                        Cantidad
                      </span>
                    </label>
                    <input
                      type="number"
                      name="cantidad"
                      value={prod.cantidad}
                      onChange={(e) =>
                        handleProductoChange(index, "cantidad", e.target.value)
                      }
                      required
                      className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                  </div>

                  <div className="flex items-center justify-start space-x-2">
                    {prod.producto ? (
                      <>
                        <div className="flex flex-col items-center">
                          <label htmlFor="editar" className="label">
                            <span className="label-text dark:text-white">
                              Editar
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={() =>
                              openEditModal(
                                productos.find((p) => p.id === prod.producto)
                              )
                            }
                            className="p-2 bg-yellow-500 text-white rounded-md"
                          >
                            <IconPencil size={20} />
                          </button>
                        </div>
                        <div className="flex flex-col items-center">
                          <label htmlFor="eliminar" className="label">
                            <span className="label-text dark:text-white">
                              Eliminar
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={() => handleRemoveProducto(index)}
                            className="p-2 bg-red-500 text-white rounded-md"
                          >
                            <IconTrash size={20} />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-col items-center">
                          <label htmlFor="crear" className="label">
                            <span className="label-text dark:text-white">
                              Crear
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={openAddModal}
                            className="p-2 bg-blue-500 text-white rounded-md"
                          >
                            <IconPencil size={20} />
                          </button>
                        </div>
                        <div className="flex flex-col items-center">
                          <label htmlFor="eliminar" className="label">
                            <span className="label-text dark:text-white">
                              Eliminar
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={() => handleRemoveProducto(index)}
                            className="p-2 bg-red-500 text-white rounded-md"
                          >
                            <IconTrash size={20} />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {formData.recepcionproducto_set.length < productos.length && (
              <button
                type="button"
                onClick={addProducto}
                className="btn border bg-blue-500 text-white dark:bg-blue-700"
              >
                Añadir Producto
              </button>
            )}
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Actualizar
              </button>
            )}
          </div>
        </form>
      </div>

      <ProductoModal
        show={isModalOpen}
        handleClose={closeModal}
        isEditMode={isEditMode}
        editData={editData}
        refreshProductos={refreshProductos}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  entrada: state.entradas.entrada,
  productos: state.productos.productos || [],
});

export default connect(mapStateToProps, { get_entrada, get_productos_list })(
  EntradasP03Editar
);
