import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { get_productos_list, get_averias_produccion } from "../../../redux/actions/P03/p03";
import Toast from "../../Alert";
import { IconTrash } from "@tabler/icons-react";

function AveriaModal({ show, handleClose, produccionId, productos, get_productos_list, get_averias_produccion, averias_list }) {
  const [averias, setAverias] = useState([{ producto: "", cantidad: "" }]);
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (show) {
      get_productos_list();
      get_averias_produccion(produccionId);
    }
  }, [show, get_productos_list, get_averias_produccion, produccionId]);

  useEffect(() => {
    if (show && averias_list) {
      const initialAverias = averias_list.averias.map(averia => ({
        producto: averia.producto.id,
        cantidad: averia.cantidad,
        id: averia.id,
      }));
      setAverias(initialAverias.length > 0 ? initialAverias : [{ producto: "", cantidad: "" }]);
    }
  }, [show, averias_list]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const selectedProductIds = averias.map((a) => a.producto);
  const filteredProductos = productos.filter(
    (producto) => !selectedProductIds.includes(producto.id)
  );
  const filteredProductosDropdown = filteredProductos.slice(0, 10);

  const handleAddAveria = () => {
    if (averias.length < productos.length) {
      setAverias([...averias, { producto: "", cantidad: "" }]);
    }
  };

  const handleRemoveAveria = (index) => {
    const newAverias = [...averias];
    newAverias.splice(index, 1);
    setAverias(newAverias);
  };

  const handleChangeAveria = (index, field, value) => {
    const newAverias = [...averias];
    newAverias[index][field] = value;
    setAverias(newAverias);
  };

  const handleSaveAverias = async (e) => {
    e.preventDefault();
    try {
      const method = averias.some(a => a.id) ? 'put' : 'post';
      const url = method === 'post' 
        ? `${process.env.REACT_APP_API_URL}/produccion/averias/create/`
        : `${process.env.REACT_APP_API_URL}/produccion/averias/${produccionId}/`;
      
      await axios[method](
        url,
        { produccion: produccionId, averias },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        }
      );
      Toast({
        tipo: "success",
        mensaje: "¡Averías guardadas correctamente!",
      });
      handleClose();
    } catch (error) {
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al guardar las averías.",
      });
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-5 rounded shadow-lg w-full max-w-lg md:max-w-2xl h-3/4 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Agregar Averías</h2>
        <form onSubmit={handleSaveAverias}>
          {averias.map((averia, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center mb-2" ref={dropdownRef}>
                <div className="relative w-full">
                  <button
                    type="button"
                    onClick={() => setDropdownOpen(index)}
                    className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <span className="mr-2">
                      {averia.producto
                        ? productos.find((p) => p.id === averia.producto)
                            ?.nombre
                        : "Seleccionar Producto"}
                    </span>
                  </button>
                  {dropdownOpen === index && (
                    <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
                      <input
                        id="search-input"
                        className="input input-bordered w-full"
                        type="text"
                        placeholder="Buscar productos"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <div className="max-h-60 overflow-y-auto">
                        {filteredProductosDropdown.length === 0 ? (
                          <div className="px-4 py-2 text-gray-700">
                            No se encontraron productos
                          </div>
                        ) : (
                          filteredProductosDropdown.map((prodItem) => (
                            <div
                              key={prodItem.id}
                              onClick={() => {
                                handleChangeAveria(index, "producto", prodItem.id);
                                setDropdownOpen(null);
                                setQuery("");
                              }}
                              className="cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100"
                            >
                              {prodItem.nombre}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <input
                  type="number"
                  value={averia.cantidad}
                  onChange={(e) =>
                    handleChangeAveria(index, "cantidad", e.target.value)
                  }
                  placeholder="Cantidad"
                  className="input input-bordered w-full ml-2"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveAveria(index)}
                  className="ml-2 p-2 bg-red-500 text-white rounded-md"
                >
                  <IconTrash size={20} />
                </button>
              </div>
            </div>
          ))}
          {averias.length < productos.length && (
            <button
              type="button"
              className="btn btn-secondary mb-4"
              onClick={handleAddAveria}
            >
              Añadir Avería
            </button>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-secondary mr-2"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  productos: state.productos.productos || [],
  averias_list: state.averiasProduccion.averias_produccion,
});

export default connect(mapStateToProps, { get_productos_list, get_averias_produccion })(AveriaModal);
