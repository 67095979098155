import React, { useEffect, useState } from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";

function Dashboard() {


  return (
    <DashboardLayout>


    </DashboardLayout>
  );
}

export default Dashboard;
