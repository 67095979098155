import { useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../../../components/Alert";
import {
  get_referencia_list,
  get_produccion,
} from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import { IconEdit, IconTrash } from "@tabler/icons-react";

function ProduccionP03Edit({
  get_referencia_list,
  get_produccion,
  refer,
  produccion,
}) {
  const params = useParams();
  const id = params.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var tipo = "min";
    get_referencia_list(tipo);
    get_produccion(id);
  }, [get_referencia_list, get_produccion, id]);

  const [formData, setFormData] = useState({
    fecha: "",
    turno: "",
    lote_soya: "",
    fv_soya: "",
    lote_palma: "",
    fv_palma: "",
    lote_mezcla: "",
    fv_mezcla: "",
    tipo_produccion: "",
    produccionreferencia_set: [],
  });

  useEffect(() => {
    if (produccion) {
      const updatedReferences = produccion.produccionreferencia_set.map(
        (ref) => ({
          referencia: ref.referencia.id,
          cantidad: ref.cantidad,
        })
      );
      setFormData({
        fecha: produccion.fecha,
        turno: produccion.turno,
        lote_soya: produccion.lote_soya,
        fv_soya: produccion.fv_soya,
        lote_palma: produccion.lote_palma,
        fv_palma: produccion.fv_palma,
        lote_mezcla: produccion.lote_mezcla,
        fv_mezcla: produccion.fv_mezcla,
        tipo_produccion: produccion.tipo_produccion,
        produccionreferencia_set: updatedReferences,
      });
    }
  }, [produccion]);

  const [referencia, setReferencia] = useState({
    referencia: "",
    cantidad: "",
  });

  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const dropdownRef = useRef([]);

  const handleDropdownToggle = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const handleDropdownClose = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = false;
      return newDropdownOpen;
    });
  };

  const filteredReferencias = refer
    ? query === ""
      ? refer.filter(
          (refItem) =>
            !formData.produccionreferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id)
        )
      : refer.filter(
          (refItem) =>
            !formData.produccionreferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id) &&
            `${refItem.codigo} - ${refItem.etiqueta}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const addReferencia = () => {
    setFormData({
      ...formData,
      produccionreferencia_set: [
        ...formData.produccionreferencia_set,
        referencia,
      ],
    });
    setReferencia({
      referencia: "",
      cantidad: "",
    });
    setQuery("");
  };

  const handleReferenciaSelect = (index, refItem) => {
    const newRefs = [...formData.produccionreferencia_set];
    newRefs[index].referencia = refItem.id;
    setFormData({ ...formData, produccionreferencia_set: newRefs });
    handleDropdownClose(index);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onReferenciaChange = (e, index) => {
    const newRefs = [...formData.produccionreferencia_set];
    newRefs[index] = { ...newRefs[index], [e.target.name]: e.target.value };
    setFormData({ ...formData, produccionreferencia_set: newRefs });
  };

  const handleDelete = (index) => {
    const newRefs = formData.produccionreferencia_set.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, produccionreferencia_set: newRefs });
  };

  const validateForm = () => {
    if (!formData.fecha || !formData.turno || !formData.tipo_produccion) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, complete todos los campos obligatorios.",
      });
      return false;
    }

    if (formData.produccionreferencia_set.length === 0) {
      Toast({
        tipo: "error",
        mensaje: "Debe agregar al menos una referencia con su cantidad.",
      });
      return false;
    }

    for (let i = 0; i < formData.produccionreferencia_set.length; i++) {
      const ref = formData.produccionreferencia_set[i];
      if (!ref.referencia || !ref.cantidad) {
        Toast({
          tipo: "error",
          mensaje: "Cada referencia debe tener una cantidad.",
        });
        return false;
      }
    }

    if (formData.lote_soya && !formData.fv_soya) {
      Toast({
        tipo: "error",
        mensaje: "Debe ingresar la fecha de vencimiento para el lote de soya.",
      });
      return false;
    }

    if (formData.lote_palma && !formData.fv_palma) {
      Toast({
        tipo: "error",
        mensaje: "Debe ingresar la fecha de vencimiento para el lote de palma.",
      });
      return false;
    }

    if (formData.lote_mezcla && !formData.fv_mezcla) {
      Toast({
        tipo: "error",
        mensaje:
          "Debe ingresar la fecha de vencimiento para el lote de mezcla.",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/produccion/update/${id}/`,
        formData,
        config
      );

      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "¡Producción actualizada correctamente!",
        });
        navigate("/produccion-p03");
      }
    } catch (error) {
      console.error(error);
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al actualizar la producción.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRef.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          handleDropdownClose(index);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const today = new Date().toISOString().split("T")[0];

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Producción.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la Producción.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-white">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                disabled
              />
            </div>

            <div className="form-control">
              <label htmlFor="turno" className="label">
                <span className="label-text dark:text-white">Turno</span>
              </label>
              <select
                name="turno"
                value={formData.turno}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccionar Turno</option>
                <option value="A">Día</option>
                <option value="B">Noche</option>
              </select>
            </div>

            <div className="form-control">
              <label htmlFor="lote_soya" className="label">
                <span className="label-text dark:text-white">Lote de Soya</span>
              </label>
              <input
                type="text"
                name="lote_soya"
                value={formData.lote_soya}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="fv_soya" className="label">
                <span className="label-text dark:text-white">
                  Fecha de Vencimiento de Soya
                </span>
              </label>
              <input
                type="date"
                name="fv_soya"
                value={formData.fv_soya}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="lote_palma" className="label">
                <span className="label-text dark:text-white">
                  Lote de Palma
                </span>
              </label>
              <input
                type="text"
                name="lote_palma"
                value={formData.lote_palma}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="fv_palma" className="label">
                <span className="label-text dark:text-white">
                  Fecha de Vencimiento de Palma
                </span>
              </label>
              <input
                type="date"
                name="fv_palma"
                value={formData.fv_palma}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="lote_mezcla" className="label">
                <span className="label-text dark:text-white">
                  Lote de Mezcla
                </span>
              </label>
              <input
                type="text"
                name="lote_mezcla"
                value={formData.lote_mezcla}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="fv_mezcla" className="label">
                <span className="label-text dark:text-white">
                  Fecha de Vencimiento de Mezcla
                </span>
              </label>
              <input
                type="date"
                name="fv_mezcla"
                value={formData.fv_mezcla}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="tipo_produccion" className="label">
                <span className="label-text dark:text-white">
                  Tipo de Producción
                </span>
              </label>
              <select
                name="tipo_produccion"
                value={formData.tipo_produccion}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccionar Tipo de Producción</option>
                <option value="Normal">Normal</option>
                <option value="EQ">EQ</option>
              </select>
            </div>
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Referencias
          </div>
          <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-3">
            {formData.produccionreferencia_set.map((ref, index) => {
              const referenciaSeleccionada = refer.find(
                (r) => r.id === ref.referencia
              );
              const productosDisponibles =
                referenciaSeleccionada?.productos || [];

              // Variables para almacenar el máximo permitido
              let maxCantidadCorrugado = Number.MAX_SAFE_INTEGER;
              let maxCantidadEnvase = Number.MAX_SAFE_INTEGER;
              let maxCantidadTapas = Number.MAX_SAFE_INTEGER;
              let maxCantidadEtiqueta = Number.MAX_SAFE_INTEGER;
              let unidades_por_caja = 0;

              // Solo calcular los máximos si la referencia está seleccionada
              if (referenciaSeleccionada) {
                productosDisponibles.forEach((producto) => {
                  if (producto.nombre === "Corrugado") {
                    const envase_size = parseInt(
                      referenciaSeleccionada.envase.split()[0],
                      10
                    );

                    if ([200, 250, 430, 450, 500].includes(envase_size)) {
                      unidades_por_caja = 24;
                    } else if (
                      [710, 800, 850, 900, 1000].includes(envase_size)
                    ) {
                      unidades_por_caja = 12;
                    } else if ([2000, 2800, 3000].includes(envase_size)) {
                      unidades_por_caja = 6;
                    } else if (envase_size === 5000) {
                      unidades_por_caja = 4;
                    }

                    maxCantidadCorrugado = Math.floor(
                      producto.inventario_data?.inventario * unidades_por_caja
                    );
                  }

                  if (producto.nombre === "Envase") {
                    maxCantidadEnvase = Math.min(
                      maxCantidadEnvase,
                      producto.inventario_data?.inventario || 0
                    );
                  }

                  if (producto.nombre === "Tapas") {
                    maxCantidadTapas = Math.min(
                      maxCantidadTapas,
                      producto.inventario_data?.inventario || 0
                    );
                  }

                  if (producto.nombre === "Etiqueta") {
                    maxCantidadEtiqueta = Math.min(
                      maxCantidadEtiqueta,
                      producto.inventario_data?.inventario || 0
                    );
                  }
                });
              }

              // El máximo número de unidades posibles está limitado por el corrugado (cajas) y los otros productos
              const maxCantidadTotal = Math.min(
                maxCantidadCorrugado,
                maxCantidadEnvase,
                maxCantidadTapas,
                maxCantidadEtiqueta
              );

              // Aseguramos que la cantidad mínima sea suficiente para completar una caja
              const esCantidadValidaParaCrear =
                maxCantidadEnvase >= unidades_por_caja &&
                maxCantidadCorrugado >= 1;

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                    <div className="form-control">
                      <label htmlFor="referencia" className="label">
                        <span className="label-text dark:text-gray-300">
                          Referencia
                        </span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (dropdownRef.current[index] = el)}
                      >
                        <button
                          type="button"
                          onClick={() => handleDropdownToggle(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 dark:text-white bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {ref.referencia && refer
                              ? `${referenciaSeleccionada?.codigo} - ${referenciaSeleccionada?.etiqueta}`
                              : "Seleccionar Referencia"}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 ml-2 -mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 011.414 0L10 11.586l2.293-2.293a1 1 111.414 1.414l-3 3a1 1 01-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        {dropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:text-white"
                              type="text"
                              placeholder="Buscar referencias"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredReferencias.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron referencias
                                </div>
                              ) : (
                                filteredReferencias
                                  .slice(0, 10)
                                  .map((refItem) => (
                                    <div
                                      key={refItem.id}
                                      onClick={() =>
                                        handleReferenciaSelect(index, refItem)
                                      }
                                      className="cursor-pointer px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                                    >
                                      {`${refItem.codigo} - ${refItem.etiqueta}`}
                                    </div>
                                  ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-control">
                      <label htmlFor="cantidad" className="label">
                        <span className="label-text dark:text-gray-300">
                          Cantidad
                        </span>
                      </label>
                      <input
                        type="number"
                        name="cantidad"
                        min="0"
                        value={ref.cantidad}
                        onChange={(e) => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          const cantidadIngresada = Math.max(
                            parseInt(e.target.value, 10),
                            0
                          ); // Asegurar que sea >= 0

                          // Si la cantidad es menor que el mínimo o mayor que el máximo, mostrar error, pero permitir la edición
                          if (cantidadIngresada === 0) {
                            // Si es 0, eliminar la referencia
                            handleDelete(index);
                          } else if (cantidadIngresada < unidades_por_caja) {
                            Toast({
                              tipo: "error",
                              mensaje: `Debe tener al menos ${unidades_por_caja} envases para completar una caja.`,
                            });
                          } else if (cantidadIngresada > maxCantidadTotal) {
                            Toast({
                              tipo: "error",
                              mensaje: `La cantidad ingresada excede el máximo posible (${maxCantidadTotal}).`,
                            });
                          } else {
                            newRefs[index].cantidad = cantidadIngresada;
                            setFormData({
                              ...formData,
                              produccionreferencia_set: newRefs,
                            });
                          }
                        }}
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>

                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      {productosDisponibles.length > 0 ? (
                        productosDisponibles.map((producto) => (
                          <div key={producto.id} className="mb-1">
                            <strong>{producto.nombre}:</strong> Inventario
                            disponible:{" "}
                            {producto.inventario_data?.inventario || 0}
                          </div>
                        ))
                      ) : (
                        <span className="text-gray-500 dark:text-gray-400">
                          Selecciona una referencia
                        </span>
                      )}
                      {productosDisponibles.length > 0 && (
                        <div>
                          <strong>Máximo posible para esta referencia:</strong>{" "}
                          {maxCantidadTotal}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col items-center">
                      <label htmlFor="eliminar" className="label">
                        <span className="label-text dark:text-gray-300">
                          Eliminar
                        </span>
                      </label>
                      <button
                        type="button"
                        onClick={() => handleDelete(index)}
                        className="p-1 text-white bg-red-500 rounded-full"
                      >
                        <IconTrash />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            {refer &&
              refer.length > formData.produccionreferencia_set.length && (
                <button
                  type="button"
                  onClick={addReferencia}
                  className="btn border bg-blue-500 text-white dark:bg-blue-600"
                >
                  Añadir Referencia
                </button>
              )}
          </div>


          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  refer: state.referencia.referencias,
  produccion: state.produccion.produccion,
});

export default connect(mapStateToProps, {
  get_referencia_list,
  get_produccion,
})(ProduccionP03Edit);
