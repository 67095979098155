import {
  GET_HISTORIAL_LIST_SUCCESS,
  GET_HISTORIAL_LIST_FAIL,
  GET_HISTORIAL_SUCCESS,
  GET_HISTORIAL_FAIL,
} from "../actions/historial/types";

const initialState = {
  historial: "",
  historial_list: "",
  count: null,
  next: null,
  previous: null,
};

export default function historial(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HISTORIAL_LIST_SUCCESS:
      return {
        ...state,
        historial_list: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_HISTORIAL_LIST_FAIL:
      return {
        ...state,
        historial_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_HISTORIAL_SUCCESS:
      return {
        ...state,
        historial: payload.historial,
      };
    case GET_HISTORIAL_FAIL:
      return {
        ...state,
        historial: null,
      };
    default:
      return state;
  }
}

