import SetPagination from "../../pagination/SetPagination";
import ReferenciasCardHorizontal from "./ReferenciasCardHorizontal";


function ReferenciasList({
  referencias,
  get_referencia_list,
  get_referencia_list_page,
  count,
}) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">
                Envase
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Peso
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Etiqueta
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Codigo
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Creacion
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Actualizacion
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {referencias &&
              referencias.map((referencia, index) => (
                <ReferenciasCardHorizontal
                  data={referencia}
                  key={index}
                  index={index}
                />
              ))} */}

            {referencias && referencias.length > 0 ? (
              referencias.map((referencia, index) => (
                <ReferenciasCardHorizontal
                  data={referencia}
                  key={index}
                  index={index}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_referencia_list_page}
        list={referencias}
        count={count}
        type="medium"
      />
    </div>
  );
}
export default ReferenciasList;
