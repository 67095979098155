import SetPagination from "../../pagination/SetPagination";
import InventarioCardHorizontal from "./InventarioCardHorizontal";


function InventarioList({
  inventario,
  get_inventario_list_page,
  count,
}) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">
                Material o Insumo
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Ingresos
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Salidas
              </th>
              <th
                scope="col"
                className="p-2 dark:text-white"
              >
                Gastos de Producción
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Inventario
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Costo Unitario
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Costo Total
              </th>
              <th scope="col" className="p-2 dark:text-white">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {inventario &&
              inventario.map((inventario, index) => (
                <InventarioCardHorizontal
                  data={inventario}
                  key={index}
                  index={index}
                />
              ))} */}

            {inventario && inventario.length > 0 ? (
              inventario.map((inventario, index) => (
                <InventarioCardHorizontal
                  data={inventario}
                  key={index}
                  index={index}
                />
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_inventario_list_page}
        list={inventario}
        count={count}
        type={"large"}
      />
    </div>
  );
}
export default InventarioList;
