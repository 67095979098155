import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { get_productos_list } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import { useNavigate } from "react-router-dom";
import Toast from "../../../components/Alert";
import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import ProductoModal from "../../../components/P03/entradas/ProductoModal";

function SalidasInsumoForm({ get_productos_list, productos }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [formData, setFormData] = useState({
    fecha: "",
    remision: "",
    descripcion: "",
    salidainsumoproducto_set: [],
    planta: "",
    cliente: "",
  });

  const dropdownRef = useRef(null);

  useEffect(() => {
    get_productos_list();
  }, [get_productos_list]);

  const filteredProductos = productos
    ? query === ""
      ? productos
      : productos.filter((producto) =>
          producto.nombre
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const selectedProductIds = formData.salidainsumoproducto_set.map(
    (p) => p.producto
  );
  const availableProductos = filteredProductos.filter(
    (producto) => !selectedProductIds.includes(producto.id)
  );
  const filteredProductosDropdown = availableProductos.slice(0, 10);

  const { fecha, remision, descripcion, planta, cliente } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (formData.salidainsumoproducto_set.length === 0) {
      Toast({
        tipo: "error",
        mensaje: "Debe agregar al menos un producto con su cantidad.",
      });
      return false;
    }
    for (let i = 0; i < formData.salidainsumoproducto_set.length; i++) {
      const prod = formData.salidainsumoproducto_set[i];
      if (!prod.producto || !prod.cantidad) {
        Toast({
          tipo: "error",
          mensaje: "Cada producto debe tener una cantidad.",
        });
        return false;
      }
    }

    if (
      !formData.fecha ||
      !formData.remision ||
      !formData.descripcion ||
      !formData.planta
    ) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, complete todos los campos obligatorios.",
      });
      return false;
    }

    if (formData.planta === "Clientes" && !formData.cliente) {
      Toast({
        tipo: "error",
        mensaje: "Debe proporcionar el nombre del cliente.",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/salidas-insumos/create/`,
        formData,
        config
      );

      if (res.status === 201) {
        Toast({
          tipo: "success",
          mensaje: "¡Salida registrada correctamente!",
        });
        navigate("/salida-insumos-P03");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        Array.isArray(error.response.data.remision) &&
        error.response.data.remision.some((msg) =>
          msg.includes("Ya existe Salida de Insumo con este remision.")
        )
      ) {
        Toast({
          tipo: "error",
          mensaje: "Ya existe Salida de Insumo con este remision.",
        });
      } else {
        Toast({
          tipo: "error",
          mensaje: "Ocurrió un error al registrar la salida.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const openAddModal = () => {
    setIsEditMode(false);
    setEditData(null);
    setIsModalOpen(true);
  };

  const openEditModal = (producto) => {
    setIsEditMode(true);
    setEditData(producto);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const refreshProductos = () => {
    get_productos_list();
  };

  const addProducto = () => {
    setFormData({
      ...formData,
      salidainsumoproducto_set: [
        ...formData.salidainsumoproducto_set,
        { producto: "", cantidad: "" },
      ],
    });
    setQuery("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg dark:text-white">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Registro de Salidas de Insumos.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Información de la salida.
          </div>
          <div className="border border-gray-300 dark:border-gray-700 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-gray-300">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={fecha}
                onChange={(e) => onChange(e)}
                required
                max={new Date().toISOString().split("T")[0]}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="remision" className="label">
                <span className="label-text dark:text-gray-300">Remision</span>
              </label>
              <input
                type="text"
                name="remision"
                value={remision}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="descripcion" className="label">
                <span className="label-text dark:text-gray-300">
                  Descripcion
                </span>
              </label>
              <input
                type="text"
                name="descripcion"
                value={descripcion}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="planta" className="label">
                <span className="label-text dark:text-gray-300">
                  Planta/Cliente
                </span>
              </label>
              <select
                name="planta"
                value={planta}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Selecciona una planta o proveedor</option>
                <option value="01">Planta Principal</option>
                <option value="02">Planta Patios</option>
                <option value="04">Planta Soplado</option>
                <option value="05">Bodega</option>
                <option value="Proveedores">Proveedor</option>
              </select>
            </div>

            {planta === "Clientes" && (
              <div className="form-control">
                <label htmlFor="cliente" className="label">
                  <span className="label-text dark:text-gray-300">
                    Nombre del Cliente
                  </span>
                </label>
                <input
                  type="text"
                  name="cliente"
                  value={cliente}
                  onChange={(e) => onChange(e)}
                  required
                  className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            )}
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Productos
          </div>
          <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-3">
            {formData.salidainsumoproducto_set.map((prod, index) => {
              // Definir productoSeleccionado dentro del map
              const productoSeleccionado = productos.find(
                (p) => p.id === prod.producto
              );

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                    <div className="form-control">
                      <label htmlFor="producto" className="label">
                        <span className="label-text dark:text-gray-300">
                          Producto
                        </span>
                      </label>
                      <div className="relative" ref={dropdownRef}>
                        <div className="inline-flex items-center justify-between w-full">
                          <button
                            type="button"
                            onClick={() => setDropdownOpen(index)}
                            className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 dark:text-white bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            <span className="mr-2">
                              {prod.producto
                                ? `${productoSeleccionado?.nombre} - ${productoSeleccionado?.referencia}`
                                : "Seleccionar Producto"}
                            </span>
                            <IconEye className="ml-2" size={20} />
                          </button>
                        </div>
                        {dropdownOpen === index && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:text-white"
                              type="text"
                              placeholder="Buscar productos"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredProductosDropdown.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron productos
                                </div>
                              ) : (
                                filteredProductosDropdown.map((prodItem) => (
                                  <div
                                    key={prodItem.id}
                                    onClick={() => {
                                      const newProds = [
                                        ...formData.salidainsumoproducto_set,
                                      ];
                                      newProds[index].producto = prodItem.id;
                                      setFormData({
                                        ...formData,
                                        salidainsumoproducto_set: newProds,
                                      });
                                      setDropdownOpen(null);
                                      setQuery("");
                                    }}
                                    className="cursor-pointer px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                                  >
                                    {prodItem.nombre} - {prodItem.referencia}
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-control">
                      <label htmlFor="cantidad" className="label">
                        <span className="label-text dark:text-gray-300">
                          Cantidad
                        </span>
                      </label>
                      <input
                        type="number"
                        name="cantidad"
                        value={prod.cantidad}
                        onChange={(e) => {
                          const newProds = [
                            ...formData.salidainsumoproducto_set,
                          ];
                          const cantidadIngresada = parseInt(
                            e.target.value,
                            10
                          );
                          const productoSeleccionado = productos.find(
                            (p) => p.id === prod.producto
                          );
                          const inventarioDisponible =
                            productoSeleccionado?.inventario_data?.inventario ||
                            0;

                          // Si el valor es vacío o no numérico, establecer cantidad en 0
                          if (
                            isNaN(cantidadIngresada) ||
                            cantidadIngresada === ""
                          ) {
                            newProds[index].cantidad = 0;
                          } else if (
                            cantidadIngresada <= inventarioDisponible &&
                            cantidadIngresada >= 0
                          ) {
                            // Si la cantidad es válida y no excede el inventario, actualizar
                            newProds[index].cantidad = cantidadIngresada;
                          } else if (cantidadIngresada > inventarioDisponible) {
                            // Mostrar error si la cantidad excede el inventario
                            Toast({
                              tipo: "error",
                              mensaje: `La cantidad ingresada excede el inventario disponible (${inventarioDisponible}).`,
                            });
                          }

                          setFormData({
                            ...formData,
                            salidainsumoproducto_set: newProds,
                          });
                        }}
                        max={
                          productoSeleccionado?.inventario_data?.inventario || 0
                        }
                        required
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />

                      {productoSeleccionado?.inventario_data && (
                        <span className="text-sm text-gray-600 dark:text-gray-300">
                          Cantidad en inventario:{" "}
                          {productoSeleccionado.inventario_data.inventario}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center justify-start space-x-2">
                      {/* Aquí mantengo los botones de Crear y Eliminar sin cambios */}
                      {prod.producto ? (
                        <>
                          <div className="flex flex-col items-center">
                            <label htmlFor="eliminar" className="label">
                              <span className="label-text dark:text-gray-300">
                                Eliminar
                              </span>
                            </label>
                            <button
                              type="button"
                              onClick={() => {
                                const newProds = [
                                  ...formData.salidainsumoproducto_set,
                                ];
                                newProds.splice(index, 1);
                                setFormData({
                                  ...formData,
                                  salidainsumoproducto_set: newProds,
                                });
                              }}
                              className="p-2 bg-red-500 text-white rounded-md"
                            >
                              <IconTrash size={20} />
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-col items-center">
                            <label htmlFor="crear" className="label">
                              <span className="label-text dark:text-gray-300">
                                Crear
                              </span>
                            </label>
                            <button
                              type="button"
                              onClick={openAddModal}
                              className="p-2 bg-blue-500 text-white rounded-md"
                            >
                              <IconPencil size={20} />
                            </button>
                          </div>
                          <div className="flex flex-col items-center">
                            <label htmlFor="eliminar" className="label">
                              <span className="label-text dark:text-gray-300">
                                Eliminar
                              </span>
                            </label>
                            <button
                              type="button"
                              onClick={() => {
                                const newProds = [
                                  ...formData.salidainsumoproducto_set,
                                ];
                                newProds.splice(index, 1);
                                setFormData({
                                  ...formData,
                                  salidainsumoproducto_set: newProds,
                                });
                              }}
                              className="p-2 bg-red-500 text-white rounded-md"
                            >
                              <IconTrash size={20} />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <button
              type="button"
              onClick={addProducto}
              className="btn border bg-blue-500 text-white"
            >
              Añadir Producto
            </button>
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>

      <ProductoModal
        show={isModalOpen}
        handleClose={closeModal}
        isEditMode={isEditMode}
        editData={editData}
        refreshProductos={refreshProductos}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  productos: state.productos.productos || [],
});

export default connect(mapStateToProps, { get_productos_list })(
  SalidasInsumoForm
);
