import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../../../../assets/img/logo-removebg-preview.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Oswald',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Oswald',
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    margin: '10px 0',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#e4e4e4',
    padding: 5,
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontFamily: 'Oswald',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 'auto',
    width: 100,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: 'center',
    color: 'grey',
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const formatDateTime = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return new Intl.DateTimeFormat('es-ES', options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  return (
    <>
      {data ? (
        <Document title={`Reporte de fecha ${data.mes}-${data.año}`}>
          <Page style={styles.body} wrap>
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>{`Reporte de Insumos Recibidos`}</Text>
            <Text style={styles.subtitle}>{`Mes: ${data.mes} - Año: ${data.año}`}</Text>
            <Text style={styles.subtitle}>{`Planta: ${data.planta}`}</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Producto</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Cantidad Total</Text>
                </View>
              </View>
              {data.reporte.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{item.producto__nombre}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{item.total_cantidad}</Text>
                  </View>
                </View>
              ))}
            </View>
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        'No se pudieron obtener los datos del cliente'
      )}
    </>
  );
}

export default MyDocument;
