// para obtener todas las entradas tanto de planta como proveedores
export const GET_ENTRADAS_LIST_SUCCESS = 'GET_ENTRADAS_LIST_SUCCESS';
export const GET_ENTRADAS_LIST_FAIL = 'GET_ENTRADAS_LIST_FAIL';

export const GET_ENTRADAS_SUCCESS = 'GET_ENTRADAS_SUCCESS';
export const GET_ENTRADAS_FAIL = 'GET_ENTRADAS_FAIL';  

export const GET_ENTRADAS_SEARCH_SUCCESS = 'GET_ENTRADAS_SEARCH_SUCCESS';
export const GET_ENTRADAS_SEARCH_FAIL = 'GET_ENTRADAS_SEARCH_FAIL';


export const GET_PRODUCTOS_LIST_SUCCESS = 'GET_PRODUCTOS_LIST_SUCCESS';
export const GET_PRODUCTOS_LIST_FAIL = 'GET_PRODUCTOS_LIST_FAIL';

export const GET_PRODUCTO_SUCCESS = 'GET_PRODUCTO_SUCCESS';
export const GET_PRODUCTO_FAIL = 'GET_PRODUCTO_FAIL';  


export const GET_REPORTE_MENSUAL_SUCCESS = 'GET_REPORTE_MENSUAL_SUCCESS';
export const GET_REPORTE_MENSUAL_FAIL = 'GET_REPORTE_MENSUAL_FAIL';

// para obtener todas las salidas 

export const GET_SALIDAS_LIST_SUCCESS = 'GET_SALIDAS_LIST_SUCCESS';
export const GET_SALIDAS_LIST_FAIL = 'GET_SALIDAS_LIST_FAIL';

export const GET_SALIDAS_SUCCESS = 'GET_SALIDAS_SUCCESS';
export const GET_SALIDAS_FAIL = 'GET_SALIDAS_FAIL';

export const GET_SALIDAS_SEARCH_SUCCESS = 'GET_SALIDAS_SEARCH_SUCCESS';
export const GET_SALIDAS_SEARCH_FAIL = 'GET_SALIDAS_SEARCH_FAIL';


// Salida insumos

export const GET_SALIDA_INSUMOS_LIST_SUCCESS = 'GET_SALIDA_INSUMOS_LIST_SUCCESS';
export const GET_SALIDA_INSUMOS_LIST_FAIL = 'GET_SALIDA_INSUMOS_LIST_FAIL';

export const GET_SALIDA_INSUMOS_SUCCESS = 'GET_SALIDA_INSUMOS_SUCCESS';
export const GET_SALIDA_INSUMOS_FAIL = 'GET_SALIDA_INSUMOS_FAIL';

export const GET_SALIDA_INSUMOS_SEARCH_SUCCESS = 'GET_SALIDA_INSUMOS_SEARCH_SUCCESS';
export const GET_SALIDA_INSUMOS_SEARCH_FAIL = 'GET_SALIDA_INSUMOS_SEARCH_FAIL';

export const GET_SALIDA_INSUMOS_REPORTE_MENSUAL_SUCCESS = 'GET_SALIDA_INSUMOS_REPORTE_MENSUAL_SUCCESS';
export const GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL = 'GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL';

// referecia

export const GET_REFERENCIA_LIST_SUCCESS = 'GET_REFERENCIA_LIST_SUCCESS';
export const GET_REFERENCIA_LIST_FAIL = 'GET_REFERENCIA_LIST_FAIL';

export const GET_REFERENCIA_SUCCESS = 'GET_REFERENCIA_SUCCESS';
export const GET_REFERENCIA_FAIL = 'GET_REFERENCIA_FAIL';

export const GET_REFERENCIA_SEARCH_SUCCESS = 'GET_REFERENCIA_SEARCH_SUCCESS';
export const GET_REFERENCIA_SEARCH_FAIL = 'GET_REFERENCIA_SEARCH_FAIL';


// Produccion 

export const GET_PRODUCCION_LIST_SUCCESS = 'GET_PRODUCCION_LIST_SUCCESS';
export const GET_PRODUCCION_LIST_FAIL = 'GET_PRODUCCION_LIST_FAIL';

export const GET_PRODUCCION_SUCCESS = 'GET_PRODUCCION_SUCCESS';
export const GET_PRODUCCION_FAIL = 'GET_PRODUCCION_FAIL';

export const GET_PRODUCCION_SEARCH_SUCCESS = 'GET_PRODUCCION_SEARCH_SUCCESS';
export const GET_PRODUCCION_SEARCH_FAIL = 'GET_PRODUCCION_SEARCH_FAIL';

// Averias Produccion

export const GET_AVERIAS_PRODUCCION_LIST_SUCCESS = 'GET_AVERIAS_PRODUCCION_LIST_SUCCESS';
export const GET_AVERIAS_PRODUCCION_LIST_FAIL = 'GET_AVERIAS_PRODUCCION_LIST_FAIL';

export const GET_AVERIAS_PRODUCCION_SUCCESS = 'GET_AVERIAS_PRODUCCION_SUCCESS';
export const GET_AVERIAS_PRODUCCION_FAIL = 'GET_AVERIAS_PRODUCCION_FAIL';

export const GET_AVERIAS_PRODUCCION_SEARCH_SUCCESS = 'GET_AVERIAS_PRODUCCION_SEARCH_SUCCESS';
export const GET_AVERIAS_PRODUCCION_SEARCH_FAIL = 'GET_AVERIAS_PRODUCCION_SEARCH_FAIL';


// Inventario

export const GET_INVENTARIO_LIST_SUCCESS = 'GET_INVENTARIO_LIST_SUCCESS';
export const GET_INVENTARIO_LIST_FAIL = 'GET_INVENTARIO_LIST_FAIL';

export const GET_INVENTARIO_SUCCESS = 'GET_INVENTARIO_SUCCESS';
export const GET_INVENTARIO_FAIL = 'GET_INVENTARIO_FAIL';

export const GET_INVENTARIO_SEARCH_SUCCESS = 'GET_INVENTARIO_SEARCH_SUCCESS';
export const GET_INVENTARIO_SEARCH_FAIL = 'GET_INVENTARIO_SEARCH_FAIL';

// Saldo Planta

export const GET_SALDO_PLANTA_LIST_SUCCESS = 'GET_SALDO_PLANTA_LIST_SUCCESS';
export const GET_SALDO_PLANTA_LIST_FAIL = 'GET_SALDO_PLANTA_LIST_FAIL';

export const GET_SALDO_PLANTA_SUCCESS = 'GET_SALDO_PLANTA_SUCCESS';
export const GET_SALDO_PLANTA_FAIL = 'GET_SALDO_PLANTA_FAIL';

export const GET_SALDO_PLANTA_SEARCH_SUCCESS = 'GET_SALDO_PLANTA_SEARCH_SUCCESS';
export const GET_SALDO_PLANTA_SEARCH_FAIL = 'GET_SALDO_PLANTA_SEARCH_FAIL';
