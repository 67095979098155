import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../../../components/Alert";
import { get_referencia_list } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import { IconTrash } from "@tabler/icons-react";

function SalidasP03Form({ get_referencia_list, refer }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    var tipo = "min";
    get_referencia_list(tipo);
  }, [get_referencia_list]);
  
  const [formData, setFormData] = useState({
    fecha: "",
    turno: "",
    remision: "",
    cliente: "",
    conductor: "",
    placa: "",
    lote: "",
    observaciones: "",
    tipo_salida: "",
    salidareferencia_set: [],
  });

  const [referencia, setReferencia] = useState({
    referencia: "",
    cantidad: "",
  });

  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const dropdownRef = useRef([]);

  const handleDropdownToggle = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const handleDropdownClose = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = false;
      return newDropdownOpen;
    });
  };

  const filteredReferencias = refer
    ? query === ""
      ? refer.filter(
          (refItem) =>
            !formData.salidareferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id)
        )
      : refer.filter(
          (refItem) =>
            !formData.salidareferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id) &&
            `${refItem.codigo} - ${refItem.etiqueta}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const addReferencia = () => {
    setFormData({
      ...formData,
      salidareferencia_set: [...formData.salidareferencia_set, referencia],
    });
    setReferencia({
      referencia: "",
      cantidad: "",
    });
    setQuery("");
  };

  const handleReferenciaSelect = (index, refItem) => {
    const newRefs = [...formData.salidareferencia_set];
    newRefs[index].referencia = refItem.id;
    setFormData({ ...formData, salidareferencia_set: newRefs });
    handleDropdownClose(index);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onReferenciaChange = (e) => {
    setReferencia({ ...referencia, [e.target.name]: e.target.value });
  };

  const handleDelete = (index) => {
    if (!formData.salidareferencia_set[index].referencia) {
      const newRefs = formData.salidareferencia_set.filter(
        (_, i) => i !== index
      );
      setFormData({ ...formData, salidareferencia_set: newRefs });
    }
  };

  const validateForm = () => {
    if (
      !formData.fecha ||
      !formData.turno ||
      !formData.remision ||
      !formData.cliente ||
      !formData.conductor ||
      !formData.placa ||
      !formData.lote ||
      !formData.tipo_salida
    ) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, complete todos los campos obligatorios.",
      });
      return false;
    }

    if (formData.salidareferencia_set.length === 0) {
      Toast({
        tipo: "error",
        mensaje: "Debe agregar al menos una referencia con su cantidad.",
      });
      return false;
    }

    for (let i = 0; i < formData.salidareferencia_set.length; i++) {
      const ref = formData.salidareferencia_set[i];
      if (!ref.referencia || !ref.cantidad) {
        Toast({
          tipo: "error",
          mensaje: "Cada referencia debe tener una cantidad.",
        });
        return false;
      }
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/salidas/create/`,
        formData,
        config
      );

      if (res.status === 201) {
        Toast({
          tipo: "success",
          mensaje: "¡Salida Registrada Correctamente!",
        });
        navigate("/salidas-p03");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.remision.includes(
          "Ya existe Salida de Envasado con este remision."
        )
      ) {
        Toast({
          tipo: "error",
          mensaje: "Ya existe Salida de Envasado con este número de remisión.",
        });
      } else {
        Toast({
          tipo: "error",
          mensaje: "Error al registrar la salida. Inténtelo de nuevo.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRef.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          handleDropdownClose(index);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Registro de Salidas.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Información de la Salida.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label
                htmlFor="fecha"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Fecha
                </span>
              </label>
              <input
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                min={today}
                max={today}
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="turno"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Turno
                </span>
              </label>
              <select
                name="turno"
                value={formData.turno}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccionar Turno</option>
                <option value="A">Día</option>
                <option value="B">Noche</option>
              </select>
            </div>

            <div className="form-control">
              <label
                htmlFor="remision"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Remisión
                </span>
              </label>
              <input
                type="text"
                name="remision"
                value={formData.remision}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="cliente"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Cliente
                </span>
              </label>
              <input
                type="text"
                name="cliente"
                value={formData.cliente}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="conductor"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Conductor
                </span>
              </label>
              <input
                type="text"
                name="conductor"
                value={formData.conductor}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="placa"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Placa
                </span>
              </label>
              <input
                type="text"
                name="placa"
                value={formData.placa}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="lote"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Lote
                </span>
              </label>
              <input
                type="text"
                name="lote"
                value={formData.lote}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="tipo_salida"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Tipo de Salida
                </span>
              </label>
              <select
                name="tipo_salida"
                value={formData.tipo_salida}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccionar Tipo de Salida</option>
                <option value="Normal">Normal</option>
                <option value="EQ">EQ</option>
              </select>
            </div>

            <div className="form-control">
              <label
                htmlFor="observaciones"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text text-gray-700 dark:text-white">
                  Observaciones
                </span>
              </label>
              <textarea
                name="observaciones"
                value={formData.observaciones}
                onChange={onChange}
                className="textarea textarea-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              ></textarea>
            </div>
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Referencias
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.salidareferencia_set.map((ref, index) => {
              const referenciaSeleccionada = refer.find(
                (r) => r.id === ref.referencia
              );

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    <div className="form-control">
                      <label
                        htmlFor="referencia"
                        className="label text-gray-900 dark:text-gray-300"
                      >
                        <span className="label-text">Referencia</span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (dropdownRef.current[index] = el)}
                      >
                        <button
                          type="button"
                          onClick={() => handleDropdownToggle(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {ref.referencia && refer
                              ? `${
                                  refer.find((r) => r.id === ref.referencia)
                                    ?.codigo
                                } - ${
                                  refer.find((r) => r.id === ref.referencia)
                                    ?.etiqueta
                                }`
                              : "Seleccionar Referencia"}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 ml-2 -mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 011.414 0L10 11.586l2.293-2.293a1 1 111.414 1.414l-3 3a1 1 01-1.414 0l-3-3a1 1 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        {dropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                              type="text"
                              placeholder="Buscar referencias"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredReferencias.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron referencias
                                </div>
                              ) : (
                                filteredReferencias
                                  .slice(0, 10)
                                  .map((refItem) => (
                                    <div
                                      key={refItem.id}
                                      onClick={() =>
                                        handleReferenciaSelect(index, refItem)
                                      }
                                      className="cursor-pointer px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
                                    >
                                      {`${refItem.codigo} - ${refItem.etiqueta}`}
                                    </div>
                                  ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-control">
                      <label
                        htmlFor="cantidad"
                        className="label text-gray-900 dark:text-gray-300"
                      >
                        <span className="label-text">Cantidad</span>
                      </label>
                      <input
                        type="number"
                        name="cantidad"
                        value={
                          ref.cantidad === 0
                            ? 0 // Mostrar 0 si la cantidad es 0
                            : ref.cantidad === ""
                            ? ""
                            : ref.cantidad
                        }
                        onChange={(e) => {
                          const newRefs = [...formData.salidareferencia_set];
                          const cantidadIngresada = parseInt(
                            e.target.value,
                            10
                          );
                          const saldoDisponible =
                            referenciaSeleccionada?.saldo_planta?.saldo || 0;

                          if (
                            cantidadIngresada === 0 ||
                            isNaN(cantidadIngresada)
                          ) {
                            // Si la cantidad llega a 0 o es vacía, mostrar 0
                            newRefs[index].cantidad = 0;
                          } else if (
                            cantidadIngresada <= saldoDisponible &&
                            cantidadIngresada > 0
                          ) {
                            // Si la cantidad es válida, actualizarla
                            newRefs[index].cantidad = cantidadIngresada;
                          } else if (cantidadIngresada > saldoDisponible) {
                            // Mostrar error si excede el saldo
                            Toast({
                              tipo: "error",
                              mensaje: `La cantidad ingresada excede el saldo disponible (${saldoDisponible}).`,
                            });
                          }

                          setFormData({
                            ...formData,
                            salidareferencia_set: newRefs,
                          });
                        }}
                        required
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                      {referenciaSeleccionada?.saldo_planta && (
                        <span className="text-sm text-gray-600 dark:text-gray-300">
                          Saldo disponible:{" "}
                          {referenciaSeleccionada.saldo_planta.saldo}
                        </span>
                      )}
                    </div>

                    <div className="flex flex-col items-center">
                      <label
                        htmlFor="eliminar"
                        className="label text-gray-900 dark:text-gray-300"
                      >
                        <span className="label-text">Eliminar</span>
                      </label>
                      <button
                        type="button"
                        onClick={() => {
                          const newRefs = [...formData.salidareferencia_set];
                          newRefs.splice(index, 1);
                          setFormData({
                            ...formData,
                            salidareferencia_set: newRefs,
                          });
                        }}
                        className="p-2 bg-red-500 text-white rounded-md"
                      >
                        <IconTrash size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            {refer && refer.length > formData.salidareferencia_set.length && (
              <button
                type="button"
                onClick={addReferencia}
                className="btn border bg-blue-500 text-white dark:bg-blue-600"
              >
                Añadir Referencia
              </button>
            )}
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  refer: state.referencia.referencias,
});

export default connect(mapStateToProps, {
  get_referencia_list,
})(SalidasP03Form);
